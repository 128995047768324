import { useCallback, useEffect, useMemo, useState } from "react"
import styled from "styled-components"
import { useAccount, useNetwork, useSendTransaction, usePrepareSendTransaction } from "wagmi"
import Button from "../../components/Button"
import Input from "../../components/Input"
import LineProgress from "../../components/LineProgress"
import LoadingButton from "../../components/LoadingButton"
import toast from "../../components/Toast/Toast"
import CONFIG from "../../config"
import { useAppDispatch, useAppSelector } from "../../helpers/hooks"
import { numToLocaleString, parseEther, stringToHex } from "../../helpers/utils"
import { defaultTheme, flexCenter } from "../../style"
import { DEFAULT_NETWORK, isChainAllowed, NETWORK_CONFIG } from "../../web3/chain"
import { useDebounce } from 'use-debounce'
import { postMethod } from "../../http"
import { useParams } from "react-router-dom"
import { TokenItem } from "../Main/Main"
import { updateCount } from "../../redux/reducer"
import { Slider as RSlider } from "@mui/material"
import { BigNumber } from "ethers"

const Slider = styled(RSlider)`
  width: 217px;
  color: #64D6AC;
  margin-left: 32px;
  ${props => !props.theme.isDesktop && `
  width: 100%;
  margin-left: 0;
`}
`

const Wrapper = styled.div`
display: flex;
height: ${props => props.theme.isDesktop ? 'calc(100vh - 101px)' : 'calc(100vh - 91px)'};
width: 100%;
align-items: center;
margin: 0 auto;
overflow-y: auto;
  .title {
    ${flexCenter};
    flex-direction: row;
    padding-top: 60px;
    padding-bottom: 30px;
    font-family: Inter;
    font-size: ${props => props.theme.isDesktop ? '50px' : '18px'};
    color: #DADADA;
    font-weight: 900;
    text-transform: uppercase;
    .main {
      color: ${defaultTheme.bgColor};
      margin: 0 10px;
    }
  }
  .box {
    display: flex;
    align-items: center;
    width: 755px;
    flex-shrink: 0;
    border-radius: 25px;
    border: 1px solid #C5C5C5;
    background: linear-gradient(112deg, #393939 -4.38%, rgba(0, 0, 0, 0.47) 91.02%);
    padding-top: 30px;
    padding-bottom: 70px;
    margin-bottom: 20px;
    ${props => !props.theme.isDesktop && `
      padding: 20px;
      margin: 20px;
      width: calc(100% - 40px);
    `}
    .header {
      color: #FFF;
      text-align: center;
      font-family: Inter;
      font-size: 40px;
      font-weight: 700;
      margin-bottom: 20px;
      text-transform: uppercase;
    }
    .link {
      color: #C1C1C1;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-decoration-line: underline;
      text-transform: capitalize;
      margin-bottom: 20px;
    }
    .tab {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 200px;
      .item {
        display: flex;
        width: 85px;
        height: 40px;
        padding: 11px 27px 12px 27px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        width: 85px;
        border-radius: 32px;
        color: #757575;
        border: 1px solid #757575;
        background: #0E0E0E;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
        cursor: ${props => props.theme.isDesktop ?  'pointer': 'none'}
      }
      .current {
        color: ${props => props.theme.bgColor};
        border-color: ${props => props.theme.primaryColor};
        background: rgba(0, 123, 110, 0.20);
      }
    }
    .row {
      display: flex;
      justify-content: center;
      algin-items: center;
      margin-top: 30px;
      width:  ${props => props.theme.isDesktop ? '400px': '100%'};
      .label {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        font-weight: 600;
        font-size: 20px;
        color: #FFF;
        margin-bottom: 14px;
        text-align: left;
        .main {
          color: ${props => props.theme.bgColor};
        }
      }
      .content {
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        .max {
          ${flexCenter};
          position: absolute;
          top: 4px;
          right: 10px;
          width: 48px;
          height: 44px;
          flex-shrink: 0;
          border-radius: 10px;
          border: 1px solid #797979;
          background: #000;
          color: #797979;
          font-family: Inter;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-transform: capitalize;
          cursor: ${props => props.theme.isDesktop ?  'pointer': 'none'};
          z-index: 3;
          // ${props => !props.theme.isDesktop && `

          // `}
        }
        .input-wrapper {
          position: relative;
          width: 100%;
          margin-top: 20px;
          input {
            width: calc(100% - 80px);
          }
        }
      }
      .reverse {
        ${props => !props.theme.isDesktop && `
        flex-direction:  column-reverse;
      `}
      }
    }
    .desc {
      ${flexCenter};
      flex-direction: row;
      width: 100%;
      margin-top: 25px;
      color: #FFF;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;
      ${props => !props.theme.isDesktop && `
        margin-bottom: 20px;
      `}
      .main-desc {
        color: #64D6AC;
        margin: 0 4px;
      }
    }
    .btns {
      ${flexCenter};
      margin-top: 30px;
    }
  }
`
const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  ${props => !props.theme.isDesktop && `
    width: 100%;
    justify-content: space-between;
  `};
  .item {
    ${flexCenter};
    width: 80px;
    height: 53px;
    flex-shrink: 0;
    font-family: Inter;
    font-size: 18px;
    color: #FFF;
    border: 1px solid #FFF;
    background: #000;
    margin-right: 4px;
    border-radius: 10px;
    cursor: ${props => props.theme.isDesktop ?  'pointer': 'none'}
  }
  .current {
    border: 1px solid ${defaultTheme.primaryColor};
    background: #000;
    color: ${defaultTheme.bgColor};
  }

`
const AMOUNT_1 = 1
const AMOUNT_100 = 100
const AMOUNT_1000 = 1000
const FEE = BigNumber.from(450000000000)
const MINT_TYPE = 'mintType'
const DELPOY_TYPE = 'delpoyType'
type Detail = Omit<TokenItem, 'hash'> & {
  maxRepeatMint: string
}

const Mint: React.FC = () => {
  const [amount, setAmount] = useState(AMOUNT_1)
  const [debouncedAmount] = useDebounce(amount, 500)
  const { chain } = useNetwork()
  const { tick: queryTick } = useParams()
  const { address } = useAccount()
  const [loading, setLoading] = useState(false)
  const [type, setType] = useState(MINT_TYPE)
  const isDesktop = useAppSelector(state => state.app.isDesktop)
  const count = useAppSelector(state => state.app.count)
  const [tick, setTick] = useState(queryTick || 'book')
  const [debouncedTick] = useDebounce(tick, 500)
  const [text, setText] = useState('')
  const [detail, setDetail] = useState<Detail>()
  const dispatch = useAppDispatch()
  const handleTXData = (data: string, repeatMint: number) => {
    const map = JSON.stringify({...JSON.parse(data), "rpm": repeatMint})
    return `0x${stringToHex(`data:,${map}`)}` as '0x'
  }
  const { sendTransaction, data, error } = useSendTransaction()
  const [newTick, setNewTick] = useState('')
  const [debouncedNewTick] = useDebounce(newTick, 500)
  const [supply, setSupply] = useState<number>()
  const [debouncedSupply] = useDebounce(supply, 500)
  const [newAmount, setNewAmount] = useState<number>()
  const [debouncedNewAmount] = useDebounce(newAmount, 500)
  const [newText, setNewText] = useState('{"p":"blr-20","op": "deploy","tick":"","max": "","lim": "","rpm":""}')


  useEffect(() => {
    if (!debouncedNewTick) return
    const map = JSON.stringify({...JSON.parse(newText), "tick": debouncedNewTick})
    setNewText(map)
  }, [debouncedNewTick, newText])

  useEffect(() => {
    if (!debouncedSupply) return
    const map = JSON.stringify({...JSON.parse(newText), "max": debouncedSupply})
    setNewText(map)
  }, [debouncedSupply, newText])

  useEffect(() => {
    if (!debouncedNewAmount) return
    const map = JSON.stringify({...JSON.parse(newText), "lim": debouncedNewAmount})
    setNewText(map)
  }, [debouncedNewAmount, newText])

  useEffect(() => {
    if (!detail) return
    const currentText =  `{"p":"blr-20","op": "mint","tick":"${detail.tick}","amt": "${detail.lim}","rpm": "1"}`
    setText(currentText)
  }, [detail])

  useEffect(() => {
    if (!data) return
    setLoading(false)
    toast({text: 'Mint Successfully'})
  }, [data])

  useEffect(() => {
    if (!error) return
    setLoading(false)
    console.error(error, 'error')
    // @ts-ignore next line
    toast({text: error?.details, type: 'error'})
  }, [error])

  useEffect(() => {
    if (!debouncedTick) return
    getTickData()
  }, [debouncedTick])

  const getTickData = () => {
    postMethod(`${CONFIG.URL}/getTick`, {tick: debouncedTick}).then(res => {
      const {data: resData} = res
      if (!resData) {
        toast({text: res.message, type: 'error'})
        return
      }
      setDetail(resData)
    })
  }

  const handleTypeChange = (currentType: string) => {
    setType(currentType)
  }

  const showDesc: boolean = useMemo(() => {
    if (!detail) return false
    return debouncedAmount * Number(detail?.lim) > Number(detail?.max) - Number(detail?.currentSupply)
  } ,[detail, debouncedAmount])

  const disabled: boolean = useMemo(() => {
    return !address || !isChainAllowed(chain?.id) || Number(detail?.max) === Number(detail?.currentSupply) || showDesc || !!detail?.isSeal
  }, [address, chain, detail, showDesc])

  const mintData = useMemo(() => {
    if (!detail) return {
      mintPre: 0,
      pre: 0
    }
    const mint = Number(detail.currentSupply) / Number(detail.max) * 100
    return {
      mintPre: mint,
      pre: mint.toFixed(2) <= '0.00' ? '0.01' : mint.toFixed(2),
    }

  }, [detail])

  const handleMint = () => {
    if (loading) return
    setLoading(true)
    sendTransaction({
      to: CONFIG.TO,
      value: BigInt(debouncedAmount) * FEE.toBigInt(),
      data: text ? handleTXData(text, debouncedAmount) : undefined,
    })
  }
  const handleShowWalletModal = useCallback(() => {
    dispatch(updateCount({count: (count || 0) + 1}))
  }, [count, dispatch])

  // const handleChange = (event: Event, newValue: number | number[]) => {
  //   if (typeof newValue === 'number') {
  //     setAmount(newValue)
  //   }
  // }

  const handleAmountChange = (num: number) => {
    if (!num || Number(num) < 1) return
    setAmount(num)
  }
  return <Wrapper>
    <div className="title">
      <span>Create The First</span>
      <span className="main">Runes</span>
      <span>on</span>
      <span className="main">BEVM</span>
    </div>
    <div className="box">
      <div className="header">BL2RUNE</div>
      {/* <div className="tab">
        <div className={type === MINT_TYPE? "item current" :  "item"} onClick={() => handleTypeChange(MINT_TYPE)}>Mint</div>
        <div className={type === DELPOY_TYPE? "item current" :  "item"}  onClick={() => handleTypeChange(DELPOY_TYPE)}>Delpoy</div>
      </div> */}
      {
        type === MINT_TYPE ?
        <div>
        <div className="row">
          <div className="label">Tick</div>
          <div className="content">
            <Input disabled value={tick} setValue={setTick}/>
          </div>
        </div>
        <div className="row">
          <div className="label"><>Process</><span className="main">{Number(detail?.holders) === 0 ? 0 : mintData.pre}%</span></div>
          <div className="content">
            <LineProgress size="large" progress={mintData.mintPre}/>
          </div>
        </div>
        <div className="row">
          <div className="label"><span>Amount Per Mint</span>
            <span>{numToLocaleString(detail?.lim || '')}</span>
          </div>
          {/* <div className="content">
          <CheckboxWrapper>
          {[AMOUNT_1, AMOUNT_100, AMOUNT_1000].map(item => (
              <div
                key={item}
                className={amount === item ? 'item current' : 'item'}
                onClick={() => setAmount(item)}
              >
                {item.toLocaleString()}
              </div>
            ))}
          </CheckboxWrapper>
          {
            isDesktop ? <>
              <Input type="number" value={amount} setValue={setAmount}/>
            <div className="max" onClick={() => setAmount(Number(detail?.maxRepeatMint) || AMOUNT_1000)}>Max</div></>
            : <div className="input-wrapper">
                <Input type="number" value={amount} setValue={setAmount}/>
            <div className="max" onClick={() => setAmount(Number(detail?.maxRepeatMint) || AMOUNT_1000)}>Max</div>
            </div>
          }

          </div> */}
        </div>
        <div className="row">
          <div className="label">Repeat Mint</div>
          <div className="content reverse">
          <div className="input-wrapper">
                <Input type="number" onlyInt min={AMOUNT_1} max={Number(detail?.maxRepeatMint) || AMOUNT_1000} value={amount} setValue={handleAmountChange}/>
                <div className="max" onClick={() => setAmount(Number(detail?.maxRepeatMint) || AMOUNT_1000)}>Max</div>
            </div>
          {/* <Slider
              min={1}
              max={Number(detail?.maxRepeatMint) || AMOUNT_1000}
              defaultValue={1}
              aria-label="Small"
              valueLabelDisplay="auto"
              onChange={handleChange}
            /> */}
          </div>
        </div>
        {/* <div className="row">
          <div className="label">Mint Text</div>
          <div className="content">
            <Input type="textarea" disabled value={text} setValue={setText}/>
          </div>
        </div> */}
        <div className="desc">
          <span>You will get </span>
          <span className="main-desc">{numToLocaleString(`${amount * Number(detail?.lim)}`)}</span>
          <span>{`${detail?.tick} (${amount} * ${Number(detail?.lim)})`}</span>
        </div>
        {
          showDesc && !detail?.isSeal && <div className="desc">
            <span>The current total mint quantity exceeds the remaining mint quantity: </span>
            <span className="main-desc">{numToLocaleString(Number(detail?.max) - Number(detail?.currentSupply))}</span>
          </div>
        }
        {
          isDesktop &&  <div className="btns">
          {
          address ? <LoadingButton size="large" onClick={handleMint} text={(loading && !disabled) ? 'Mint...' : 'Mint'} disabled={disabled}/>
          : <LoadingButton size="large" text="Connect Wallet" onClick={handleShowWalletModal} />
          }

        </div>
        }
        {
          !isDesktop && <LoadingButton size="large" onClick={handleMint} text={loading ? 'Mint...' : 'Mint'} disabled={disabled}/>
        }

      </div>
        : <>
          <div className="row">
            <div className="label">Tick</div>
            <div className="content">
              <Input value={newTick}
              replace={RegExp(/[^\w]/gi)}
              maxLength={10} minLength={3} placeholder="4 characters like “abcd”" setValue={setNewTick}/>
            </div>
          </div>
          <div className="row">
            <div className="label">Supply</div>
            <div className="content">
              <Input value={supply} onlyInt min={AMOUNT_1} placeholder="like “21,000,000”" type="number" setValue={setSupply}/>
            </div>
          </div>
          <div className="row">
            <div className="label">Amount Per Mint</div>
            <div className="content">
              <Input value={newAmount} onlyInt min={AMOUNT_1} max={supply} placeholder="like “1,000”" type="number"  setValue={setNewAmount}/>
            </div>
          </div>
          <div className="row">
            <div className="label">Mint Text</div>
            <div className="content">
              <Input type="textarea" disabled value={newText} setValue={setNewText}/>
            </div>
          </div>
          {
          isDesktop &&  <div className="btns">
          {
          address ? <LoadingButton size="large" onClick={handleMint} text={(loading && !disabled) ? 'Query...' : 'Query'} disabled={disabled}/>
          : <LoadingButton size="large" text="Connect Wallet" onClick={handleShowWalletModal} />
          }

        </div>
        }
        {
          !isDesktop && <LoadingButton size="large" onClick={handleMint} text={loading ? 'Query...' : 'Query'} disabled={disabled}/>
        }
        </>
      }

    </div>
  </Wrapper>
}

export default Mint
