/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { isDesktop } from '../helpers/utils'
import { DEFAULT_FONT_SIZE } from '../style'

interface AppState {
  isDesktop: boolean
  lastTimestamp: number
  okxAddress?: string
  okxChainId?: string
  count: number
}

const handleView = (desktopValue: boolean) => {
  // 如果是尺寸的设计稿在这里修改
  const WIDTH = 750
  const IPHONE = 375
  // 设置html标签的fontSize
  document.documentElement.style.fontSize = desktopValue
    ? DEFAULT_FONT_SIZE
    : `${(100 * IPHONE) / WIDTH}px`
}

const initialState: AppState = {
  isDesktop,
  lastTimestamp: 0,
  okxAddress: window.okxwallet?.selectedAddress || '',
  okxChainId: window.okxwallet?.chainId || '',
  count: 0,
}
const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    updateDesktop(state, { payload: { desktopValue } }) {
      handleView(desktopValue)
      state.isDesktop = desktopValue
    },
    updateokxAddress(state, { payload: { okxAddress } }) {
      state.okxAddress = okxAddress
    },
    updateokxChainId(state, { payload: { okxChainId } }) {
      state.okxChainId = okxChainId
    },
    updateCount(state, { payload: { count } }) {
      state.count = count
    },
  },
})

export const { updateDesktop, updateokxAddress, updateokxChainId, updateCount } = appSlice.actions
export default appSlice.reducer
