import styled from 'styled-components'
import { NumericFormat, NumericFormatProps } from 'react-number-format'
import { scrollbar } from '../../style'

type InputProps = React.ComponentProps<'input'>

export const BaseInput = styled.input`
  width: 100%;
  padding: 15px 20px;
  color: #fff;
  font-size: 18px;
  border-radius: 10px;
  border: 1px solid #FFF;
  background: #000;
  font-weight: 500;
  font-family: Inter;
  :focus-visible {
    outline: none;
  }
`
const BaseTextarea = styled.textarea`
  width: 100%;
  border: 0;
  padding: 15px 20px;
  color: #fff;
  font-size: 18px;
  border-radius: 10px;
  border: 1px solid #FFF;
  background: #000;
  font-weight: 500;
  font-family: Inter;
  height: 40px;
  resize: none;
  overflow-y: auto;
  line-height: 27px;
  ${scrollbar};
  :focus-visible {
    outline: none;
  }
`

export const NumberInput = styled(NumericFormat)`
width: 100%;
border: 0;
padding: 15px 20px;
padding-right: 60px;
color: #fff;
font-size: 18px;
border-radius: 10px;
border: 1px solid #FFF;
background: #000;
font-weight: 500;
font-family: Inter;
:focus-visible {
  outline: none;
}
z-index: 1;
:focus-visible {
  outline: none;
  -webkit-appearance: none; /*去除系统默认的样式*/
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); /* 点击高亮的颜色*/
}

`



const MAX_LIMIT = 10 ** 18
const LIMIT = 18

type Iprop = {
  value: any
  decimalScale?: NumericFormatProps['decimalScale']
  allowNegative?: NumericFormatProps['allowNegative']
  onlyInt?: boolean
  maxLength?: number
  minLength?: number
  replace?: RegExp
  setValue: (value: any) => void
  onBlur?: () => void
} & InputProps
const Input: React.FC<Iprop> = prop => {
  const {
    setValue,
    onBlur,
    type = 'text',
    placeholder,
    value,
    decimalScale = 6,
    disabled,
    maxLength,
    replace,
    minLength,
    allowNegative = false,
    onlyInt = false,
    max = MAX_LIMIT,
  } = prop

  if (type === 'textarea') {
    return (
      <BaseTextarea
      value={value}
      onBlur={onBlur}
      disabled={disabled}
      onChange={e => {
        setValue(e.target.value)
      }}
      placeholder={placeholder}
      />
    )
  }

  return (
    <>
      {type === 'number' ? (
        <NumberInput
          allowLeadingZeros={false}
          decimalScale={decimalScale}
          allowNegative={allowNegative}
          disabled={disabled}
          value={value}
          maxLength={maxLength}
          minLength={minLength}
          onBlur={onBlur}
          onValueChange={(values: { value: any }) => {
            if (values.value.length < MAX_LIMIT) {
              if (onlyInt) {
                const val = values.value.replace(/[^\d]/g, '')
                setValue(val)
                return
              }
              setValue(values.value)
            } else {
              if (onlyInt) {
                const val = values.value.replace(/[^\d]/g, '')
                setValue(Number(val.substring(0, MAX_LIMIT)))
                return
              }
              setValue(Number(values.value.substring(0, MAX_LIMIT)))
            }
          }}
          isAllowed={(values: { value: any }) => {
            return Number(values.value) <= max
          }}
          placeholder={placeholder}
        />
      ) : (
        <BaseInput
          value={value}
          onBlur={onBlur}
          disabled={disabled}
          maxLength={maxLength}
          minLength={minLength}
          onChange={e => {
            if (replace) {
              const val = e.target.value.replace(replace, '')
              setValue(val)
              return
            }
            setValue(e.target.value)
          }}
          placeholder={placeholder}
        />
      )}
    </>
  )
}

export default Input
