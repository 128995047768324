import { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { useAppSelector, useCurrentPath } from '../../helpers/hooks'
import { flexCenter } from '../../style'
import Popover from '../Popover'
import MENU from "./menu.svg"

const Wrapper = styled.div`
  display: flex;
  width: 320px;
  justify-content: space-around;
  flex-direction: row;
  .item {
    position: relative;
    color: #fff;
    font-size: 14px;
    etter-spacing: 0.28px;
  }
  .current {
    background: linear-gradient(90deg, #007B6E 0%, #63D5AD 50.08%, #007B6E 98.11%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 900;
  }
  .disabled {
    color: #757575;
    cursor: not-allowed;
  }
  .tips {
    position: absolute;
    right: -45px;
    top: -3px;
    border-radius: 10px;
    border: 1px solid #757575;
    background: #202020;
    color: #757575;
    font-size: 14px;
    padding: 3px 2px;
  }
`

const MobileWrapper = styled.div`
.menu-icon {
  width: auto;
  height: 22px;
}
`
const PopBody = styled.div`
  display: flex;
  justify-content: space-around;
  width: 130px;
  height: 140px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #FFF;
  background: #000;
  .item {
    ${flexCenter};
    flex-direction: row;
    color: #D8D8D8;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 141.7%; /* 22.672px */
    letter-spacing: 0.16px;
  }
  .current {
    color: ${prop => prop.theme.primaryColor};
  }

`
const MENU_KEY = 'menu'
const Tab: React.FC = () => {
  const currentPath = useCurrentPath()
  const isDesktop = useAppSelector(state => state.app.isDesktop)
  const anchorElRef = useRef<HTMLDivElement>(null)
  const [show, setShow] = useState(false)


  if (!isDesktop) {
    return <><MobileWrapper
      ref={anchorElRef}
      aria-describedby={MENU_KEY}
      onClick={() => setShow(true)}
      aria-haspopup="true">
      <img className='menu-icon' src={MENU} />
    </MobileWrapper>
    <Popover
          open={show}
          id={MENU_KEY}
          anchorEl={anchorElRef.current}
          onClose={() => setShow(false)}
          >
            <PopBody>
            <Link to="/tokens">
              <div className={currentPath === '/tokens' ? 'item current' : 'item'}>Tokens</div>
            </Link>
            <Link to="/mint">
              <div className={currentPath.includes('mint') ? 'item current' : 'item'}>Inscribe</div>
            </Link>
            <Link to="/personal">
              <div className={currentPath === '/personal'  ? 'item current' : 'item'}>My Runes</div>
            </Link>
            <Link to="/market">
              <div className={currentPath.includes('market') ? 'item current' : 'item'}>Marketplace</div>
            </Link>
            </PopBody>
          </Popover>
    </>
  }

  return (
    <Wrapper>
      <Link to="/tokens">
        <div className={currentPath === '/tokens' ? 'item current' : 'item'}>Tokens</div>
      </Link>
      <Link to="/mint">
        <div className={currentPath.includes('mint') ? 'item current' : 'item'}>Inscribe</div>
      </Link>
      <Link to="/market">
        <div className={currentPath.includes('market') ? 'item current' : 'item'}>Marketplace</div>
      </Link>
    </Wrapper>
  )
}

export default Tab
