import { Link, useNavigate, useParams } from "react-router-dom"
import styled from "styled-components"
import Button from "../../components/Button"
import VerifyIcon from '../../images/verified-badge.svg'
import BitcoinIcon from '../../images/bitcoin-icon.svg'
import PersonIcon from "./person-icon.svg"
import CardIcon from "./card-icon.svg"
import RefreshIcon from '../../images/refresh.svg'
import { CheckBoxWrapper } from "../Main/Main"
import { useEffect, useMemo, useState } from "react"
import { TokenItem } from "./TokenItem"
import CONFIG from "../../config"
import { getMethod, postMethod } from "../../http"
import toast from "../../components/Toast/Toast"
import { MarketItem } from "../Market/Market"
import { useAccount, useContractRead, useContractWrite, useNetwork, usePublicClient, useTransaction, useWalletClient } from "wagmi"
import { formatEther, formatSARS, numToLocaleString, parseEther, parseSARS, toDecimalNum, toFloor } from "../../helpers/utils"
import { useAppSelector } from "../../helpers/hooks"
import Modal from "../../components/Modal"
import { ModalContent } from "../Personal/Personal"
import Input from "../../components/Input/Input"
import LoadingButton from "../../components/LoadingButton/LoadingButton"
import { isChainAllowed } from "../../web3/chain"
import MarketABI from '../../web3/abi/Market.json'
import { BigNumber } from "ethers"
import OTable from "../../components/OTable"
import { scrollbar } from "../../style"
import { InfiniteScrollWrapper } from "../../components/Table/Table"
import SatsIcon from '../../images/sats-icon.svg'

const Wrapper = styled.div`
  display: flex;
  height: ${props => props.theme.isDesktop ? 'calc(100vh - 101px)' : 'calc(100vh - 91px)'};
  width: 100%;
  align-items: center;
  margin: 0 auto;
  overflow-y: auto;
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: ${props => props.theme.isDesktop ? '1116px' : '100%'};
    margin-top: 60px;
    margin-bottom: 30px;
    color: #DADADA;
    text-align: center;
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    text-transform: capitalize;
    .back {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .icon {
      color: #64D6AC;
      font-size: 26px;
      margin-right: 10px;
      cursor: pointer;
    }
    ${props => !props.theme.isDesktop && `
      margin-top: 30px;
      margin-bottom: 20px;
      padding-left: 20px;
    `}
  }
  .token-deatil {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: ${props => props.theme.isDesktop ? '1116px' : '100%'};
    margin: 18px 0;
    .left {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #64D6AC;
      font-family: Inter;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: capitalize;
      .verify-icon {
        width: 32px;
        height: 32px;
        margin-left: 6px;
      }
    }
    button {
      width: 165px;
      height: 39px;
      border-radius: 10px;
    }
    ${props => !props.theme.isDesktop && `
    margin-top: 0px;
    margin-bottom: 10px;
    padding: 0 20px;
    button {
      font-size: 18px;
    }
  `}
  }
  .token-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: ${props => props.theme.isDesktop ? '1116px' : '100%'};
    border-radius: 25px;
    border: 1px solid #C5C5C5;
    background: linear-gradient(112deg, #393939 -4.38%, rgba(0, 0, 0, 0.47) 91.02%);
    padding: 24px 76px;
    .item {
      display: flex;
      position: relative;
      .item-name {
        color: #757575;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
        margin-bottom: 17px;
      }
      .item-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #FFF;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
        .item-icon {
          width: 22px;
          height: 22px;
          flex-shrink: 0;
          margin-right: 8px;
        }
      }
      :before {
        position: absolute;
        content: "";
        left: -8px;
        top: 0;
        width: 3px;
        height: 100%;
        background: linear-gradient(180deg, #068072 0%, #64D6AC 100%);
      }
    }
    ${props => !props.theme.isDesktop && `
    flex-direction: column;
    margin: 0px 20px;
    width: calc(100% - 40px);
    padding: 24px 20px;
    .item {
      text-align: center;
      margin-bottom: 10px;
      :before {
        display: none;
      }
    }
  `}
  }
  .options {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: ${props => props.theme.isDesktop ? '26px 0' : '20px'} ;
    width: ${props => props.theme.isDesktop ? '1116px' : '100%'};
    .refresh-icon {
      cursor: ${props => props.theme.isDesktop ?  'pointer': 'none'}
      width: 24px;
      height: 24px;
    }
  }
  .list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: ${props => props.theme.isDesktop ? '1116px' : '100%'};
    ${props => !props.theme.isDesktop && `
      justify-content: center;
    `}
  }
  .no-data {
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    text-align: center;
    color: #fff;
    margin-top: 20px;
    width: 100%;
  }
`

export const LISTED = 'Listed'
export const ORDERS = 'Orders'
export const MYLISTED = 'My Listed'

export interface ListedItem {
  tick: string
  owner: string
  amount: string
  total: string
  orderId: string
  uintPrice: string
}

export interface OrderedItem {
  event: string
  orderId: string
  tick: string
  hash: string
  amount: string
  total: string
  txTimestamp: number
}

const Token: React.FC = () => {
  const navigate = useNavigate()
  const [type, setType] = useState(LISTED)
  const { tick: queryTick } = useParams()
  const [detail, setDetail] = useState<MarketItem>()
  const [list, setList] = useState<ListedItem[]>([])
  const [myList, setMyList] = useState<ListedItem[]>([])
  const [order, setOrder] = useState<OrderedItem[]>([])
  const { address } = useAccount()
  const [btcPrice, setbtcPrice] = useState(0)
  const [userBalance, setUserBalance] = useState(0)
  const [hasMore, sethasMore] = useState(true)
  const [page, setPage] = useState(1)
  const [hasMyOrderMore, setHasMyOrderMore] = useState(true)
  const [myOrderPage, setMyOrderPage] = useState(1)
  const [hasUserTickOrdersMore, setHasUserTickOrdersMore] = useState(true)
  const [userTickOrderPage, setUserTickOrderPage] = useState(1)
  const [show, setShow] = useState(false)
  const isDesktop = useAppSelector(state => state.app.isDesktop)
  const [amount, setAmount] = useState(1)
  const [totalPrice, setTotalPrice] = useState<number>()
  const { chain } = useNetwork()
  const [loading, setLoading] = useState(false)
  const [feeRate, setFeeRate] = useState(0)

  const { write, data: listedData, error: listError } = useContractWrite({
    abi: MarketABI,
    address: CONFIG.MARKET_ADDRESS as '0x',
    functionName: 'list',
  })
  const listResult = useTransaction({
    hash: listedData?.hash
  })
  const { data: info } = useContractRead({
    abi: MarketABI,
    address: CONFIG.MARKET_ADDRESS as '0x',
    functionName: 'getInfo',
  })

  useEffect(() => {
    if (!info) return
    const infoList: any = info
    const fee = Number(infoList[1]) / Number(infoList[2])
    setFeeRate(fee)
  }, [info])

  useEffect(() => {
    if (address && queryTick) {
      init()
    }
  }, [address, queryTick])

  useEffect(() => {
    if(!queryTick) return
    getTickMarket()
    getBTCPrice()
  }, [queryTick])

  useEffect(() => {
    if (!listResult.isSuccess || !loading) return
    setLoading(false)
    toast({text: 'List Successfully'})
    getOrders(page)
  }, [listResult, loading])

  useEffect(() => {
    if (!listError) return
    setLoading(false)
    console.error('Error', listError)
    // @ts-ignore next line
    toast({text: listError?.details, type: 'error'})
  }, [listError])

  useEffect(() => {
    if(!queryTick || !address) return
    postMethod(`${CONFIG.URL}/getUserBalance`, {tick: queryTick, address}).then(res => {
      const {data: resData} = res
      if (!resData) {
        toast({text: res.message, type: 'error'})
        return
      }
      setUserBalance(Number(resData.balance))
    })
  }, [queryTick, address])

  const getTickMarket = () => {
    postMethod(`${CONFIG.URL}/getTickMarket`, {tick: queryTick}).then(res => {
      const {data: resData} = res
      if (!resData) {
        toast({text: res.message, type: 'error'})
        return
      }
      setDetail(resData)
    })
  }

  const getBTCPrice = () => {
    postMethod(`${CONFIG.URL}/getBTCPrice`, {}).then(res => {
      setbtcPrice(Number(res.data.price))
    })
  }

  const getOrders = (index: number) => {
    if (index !== 1 && !hasMore) return
    postMethod(`${CONFIG.URL}/getTickListingOrder`, {
      tick: queryTick, size: 10,page: index,
    }).then(res => {
      const {data: resData} = res
      if (!resData) {
        toast({text: res.message, type: 'error'})
        return
      }
      const {orders, pageDetial} = resData
      const {totalSize, currentPage} = pageDetial
      if (index === 1) {
        setList([...orders])
      } else {
        setList([...list, ...orders])
      }
      sethasMore(totalSize > currentPage * 10)
      setPage(currentPage)
    })
  }

  const getUserTickOrders = (index: number, addr: string) => {
    if (index !== 1 && !hasUserTickOrdersMore) return
    postMethod(`${CONFIG.URL}/getUserTickListingOrders`, {
      tick: queryTick, size: 10,page: index, address: addr,
    }).then(res => {
      const {data: resData} = res
      if (!resData) {
        toast({text: res.message, type: 'error'})
        return
      }
      const {orders, pageDetial} = resData
      const {totalSize, currentPage} = pageDetial
      if (index === 1) {
        setMyList([...orders])
      } else {
        setMyList([...myList, ...orders])
      }
      setHasUserTickOrdersMore(totalSize > currentPage * 10)
      setUserTickOrderPage(currentPage)
    })
  }
  const getMyOrders = (index: number, addr: string) => {
    if (index !== 1 && !hasMyOrderMore) return
    postMethod(`${CONFIG.URL}/getUserTickOrderHistory`, {
      tick: queryTick, size: 10,page: index, address: addr,
    }).then(res => {
      const {data: resData} = res
      if (!resData) {
        toast({text: res.message, type: 'error'})
        return
      }
      const {orderHistory, pageDetial} = resData
      const {totalSize, currentPage} = pageDetial
      if (index === 1) {
        setOrder([...orderHistory])
      } else {
        setOrder([...order, ...orderHistory])
      }
      setHasMyOrderMore(totalSize > currentPage * 10)
      setMyOrderPage(currentPage)
    })
  }
  const init = () => {
    getOrders(1)
    getUserTickOrders(1, address!)
    getMyOrders(1, address!)
  }
  const nextFunc = () => {
    if (type === LISTED) {
      getOrders(page + 1)
    } else if (type === ORDERS && address) {
      getUserTickOrders(userTickOrderPage + 1, address)
    } else if (type === MYLISTED && address)  {
      getMyOrders(myOrderPage + 1, address)
    }
  }
  const handleRefresh = () => {
    init()
  }
  const handleList = () => {
    if (!detail || !totalPrice || loading || !amount) return
    setLoading(true)
    write({
      args: [detail.tick, Number(amount), parseEther(totalPrice)]
    })
  }

  const refreshData = () => {
    setTimeout(() => {
      init()
    }, 5000)
  }

  const listDisabled: boolean = useMemo(() => {
    return !address || !isChainAllowed(chain?.id) || !totalPrice
  }, [address, chain, totalPrice])

  const price = useMemo(() => {
    if (!totalPrice || !btcPrice) return '0.00'
    return toFloor(toDecimalNum(formatEther(parseEther(totalPrice).mul(parseEther(btcPrice)))))
  }, [totalPrice, btcPrice])


  return <Wrapper>
    <div className="header">
      <div className="back" onClick={() => navigate(-1)}>
        <span className="icon">{'<'}</span>
        <span>Marketplace</span>
      </div>

    </div>
    <div className="token-deatil">
      <div className="left">
        <span>{detail?.tick}</span>
        {detail?.tick === CONFIG.verifyRune && <img src={VerifyIcon} className="verify-icon" />}
      </div>
      <Button text="List" onClick={() => setShow(true)} disabled={!userBalance} />
    </div>
    <div className="token-info">
      <div className="item">
        <div className="item-name">Floor Price</div>
        {
          Number(detail?.floorPrice ) < 0.000001 ?
          <div className="item-row">
          <img src={SatsIcon} className="item-icon" />
          <span>{detail?.floorPrice && toFloor(parseSARS(detail.floorPrice), 3)} Sats</span>
          </div>
          :
          <div className="item-row">
          <img src={BitcoinIcon} className="item-icon" />
          <span>{detail?.floorPrice} BTC</span>
          </div>
      }

      </div>
      <div className="item">
        <div className="item-name">Volume(24h)</div>
        {detail?.oneDayVolume &&
          formatEther(BigNumber.from(detail?.oneDayVolume)) < 0.0001 ?
          <div className="item-row">
          <img src={SatsIcon} className="item-icon" />
          <span>{toFloor(formatSARS(BigNumber.from(detail?.oneDayVolume)), 3)} Sats</span>
        </div>
        :
        <div className="item-row">
        <img src={BitcoinIcon} className="item-icon" />
        <span>{detail?.oneDayVolume && toFloor(formatEther(BigNumber.from(detail?.oneDayVolume)))} BTC</span>
      </div>
        }


      </div>
      <div className="item">
        <div className="item-name">Total Volume</div>
        {detail?.totalVolume &&
        formatEther(BigNumber.from(detail?.totalVolume)) < 0.0001 ?
          <div className="item-row">
          <img src={SatsIcon} className="item-icon" />
          <span>{detail?.totalVolume && toFloor(formatSARS(BigNumber.from(detail?.totalVolume)), 3)} Sats</span>
        </div>
        :
        <div className="item-row">
        <img src={BitcoinIcon} className="item-icon" />
        <span>{detail?.totalVolume && toFloor(formatEther(BigNumber.from(detail?.totalVolume)))} BTC</span>
      </div>
        }

      </div>
      <div className="item">
        <div className="item-name">Holders</div>
        <div className="item-row">
          <img src={PersonIcon} className="item-icon" />
          <span>{numToLocaleString(detail?.holders || '')}</span>
        </div>
      </div>
      <div className="item">
        <div className="item-name">Supply</div>
        <div className="item-row">
          <img src={CardIcon} className="item-icon" />
          <span>{numToLocaleString(detail?.supply || '')}</span>
        </div>
      </div>
    </div>
    <div className="options">
        <CheckBoxWrapper>
          {[LISTED,ORDERS,MYLISTED].map(item => (
            <div
              key={item}
              className={type === item ? 'item current' : 'item'}
              onClick={() => setType(item)}
            >
              {item}
            </div>
          ))}
        </CheckBoxWrapper>
        <img className="refresh-icon" src={RefreshIcon} onClick={handleRefresh}/>
    </div>
    {
      type === LISTED &&
      <InfiniteScrollWrapper
      dataLength={list.length}
      next={nextFunc}
      hasMore={hasMore}
      height={685}
      loader={null}
      endMessage={null}
    >
      <div className="list">
      {!list.length && <div className="no-data">No Data</div>}
        {
          !!list.length && list.map(item => (
              <TokenItem data={{...item, btcPrice, type, feeRate, refreshData}} key={item.orderId}/>
          ))
        }
      </div>
      </InfiniteScrollWrapper>
    }
    {
      type === MYLISTED &&
      <InfiniteScrollWrapper
      dataLength={myList.length}
      next={nextFunc}
      hasMore={hasUserTickOrdersMore}
      height={685}
      loader={null}
      endMessage={null}
    >
      <div className="list">
      {!myList.length && <div className="no-data">No Data</div>}
        {
          !!myList.length && myList.map(item => (
              <TokenItem data={{...item, btcPrice, type, feeRate, refreshData}} key={item.orderId}/>
          ))
        }
      </div>
      </InfiniteScrollWrapper>
    }
    {
      type === ORDERS && <OTable  data={{
        list: order,
        hasMore: hasMyOrderMore,
        nextFunc
      }}/>
    }
      <Modal
        open={!!detail && show}
        width={isDesktop ? 520 : 300}
        title={`List ${detail?.tick.toUpperCase()} For Sale`}
        showCloseIcon={false}
        onClose={() => setShow(false)}
      >
        <ModalContent>
          <div className="row">
            <div className="label">Tick</div>
            <div className="value">{detail?.tick}</div>
            {CONFIG.verifyRune === detail?.tick &&  <img src={VerifyIcon} className="icon" />}
          </div>

          <div className={isDesktop ? "row" : "row row-wrap"}>
            <div className="label">Amount</div>
            <div className="input-wrapper">
              <Input
              type="number" onlyInt
              placeholder="Enter The Amount"
              value={amount}
              min="1"
              max={userBalance}
              setValue={setAmount}/>
              <div className="max" onClick={() => setAmount(Number(userBalance))}>Max</div>
            </div>
          </div>
          <div className="row" style={{"marginTop": "-30px"}}>
            <div className="xvb">Balance</div>
            <div className="xvb main">{userBalance}</div>
          </div>
          <div className={isDesktop ? "row" : "row row-wrap"}>
            <div className="label">Total Price</div>
            <div className="input-wrapper">
              <Input
              type="number"
              placeholder="Enter the Price"
              value={totalPrice}
              maxLength={16}
              setValue={setTotalPrice}/>
            </div>
          </div>
          <div className="row">
            <div className="xvb">Total Price</div>
            <div className="xvb main">{totalPrice} BTC ≈ ${price}</div>
          </div>
          <div className="row">
            <div className="xvb">Fee</div>
            <div className="xvb">{feeRate * 100}%</div>
          </div>
          <LoadingButton disabled={listDisabled} size="large" loading={loading} text="List" onClick={handleList}/>
        </ModalContent>
      </Modal>
  </Wrapper>
}

export default Token
