import { StyledEngineProvider, useMediaQuery } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import styled, { DefaultTheme, ThemeProvider as MyThemeProvider } from 'styled-components'
import { ThemeContext, useAppDispatch, useAppSelector, useCurrentPath } from './helpers/hooks'
import { updateDesktop } from './redux/reducer'
import { darkTheme, defalutFont, GlobalStyle, lightTheme, mobileFont } from './style'
import '@mui/styles'
import { DESKTOP_WIDTH } from './helpers/utils'
import BGIMG from './images/bg.png'

const darkMap = new Map()
const desktopMap = new Map()
desktopMap.set(true, defalutFont)
desktopMap.set(false, mobileFont)
darkMap.set(true, darkTheme)
darkMap.set(false, lightTheme)

const BGContainers = styled.div<{ isHome: boolean }>`
  background-image: url(${BGIMG});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  ${props => props.isHome && `
    background-image: none;
    background-color: #000;
  `}
`

export const ThemeProvider: React.FC<{ children: React.ReactElement }> = ({ children }) => {
  const [darkMode, setDarkMode] = useState(true)
  const dispatch = useAppDispatch()
  const matchesDesktop = useMediaQuery(`(min-width: ${DESKTOP_WIDTH}px)`)
  const isDesktop = useAppSelector(state => state.app.isDesktop)
  const currentPath = useCurrentPath()

  useEffect(() => {
    dispatch(updateDesktop({ desktopValue: matchesDesktop }))
  }, [matchesDesktop, dispatch])

  const getTheme = useCallback((): DefaultTheme => {
    return { ...darkMap.get(darkMode), ...desktopMap.get(isDesktop), isDesktop }
  }, [darkMode, isDesktop])

  return (
    <ThemeContext.Provider value={{ darkMode, setDarkMode }}>
      <StyledEngineProvider injectFirst>
        <MyThemeProvider theme={getTheme()}>
          <BGContainers isHome={currentPath=== '/'}>
            <GlobalStyle />
            {children}
          </BGContainers>
        </MyThemeProvider>
      </StyledEngineProvider>
    </ThemeContext.Provider>
  )
}
