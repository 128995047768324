import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { flexCenter } from '../../style'
import LoadingButton from '../LoadingButton'
import VerifyIcon from '../../images/verified-badge.svg'
import CONFIG from '../../config'
import { MaterialTableProps } from '../GTable/GTable'
import { InfiniteScrollWrapper } from '../Table/Table'
import { formatEther, formatSARS, parseSARS, toFloor } from '../../helpers/utils'
import { BigNumber } from 'ethers'

const Wrapper = styled.div`
  display: flex;
  padding: 20px;
  width: 100%;
  .box {
    width: 100%;
    border-radius: 25px;
    border: 1px solid #C5C5C5;
    background: linear-gradient(112deg, #393939 -4.38%, rgba(0, 0, 0, 0.47) 91.02%);
    padding: 20px 30px;
    margin-bottom: 20px;
    .row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      color: #C1C1C1;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: capitalize;
      margin-bottom: 20px;
    }
    .header {
      margin-bottom: 20px;
      font-size: 22px;
      color: #FFF;
      .icon {
        width: 20px;
        margin-left: 10px;
        margin-right: auto;
      }
    }
    .btns {
      ${flexCenter}
    }
  }

`

const MobileTable: React.FC<MaterialTableProps> = ({data}) => {
  const navigate = useNavigate()
  const {list, hasMore, nextFunc} = data

  return <Wrapper>
          <InfiniteScrollWrapper
            dataLength={list.length}
            next={nextFunc}
            hasMore={hasMore}
            height={670}
            loader={null}
            endMessage={null}
          >
      {
        !!list.length && list.map((item, index) => {
          return (
          <div className='box' key={item.tick}>
            <div className='row header'>
              <div>{item.tick}</div>
              {CONFIG.verifyRune === item.tick &&  <img src={VerifyIcon} className="icon" />}
              <div>#{index + 1}</div>
            </div>
            <div className='row'>
              <span>Volume(24h)</span>
              <span>{toFloor(formatSARS(BigNumber.from(item.oneDayVolume)), 3)}</span>
            </div>
            <div className='row'>
              <span>Total Volume</span>
              <span>{toFloor(formatEther(BigNumber.from(item.totalVolume)))}</span>
            </div>
            <div className='row'>
              <span>Floor Price</span>
              <span>{toFloor(parseSARS(item.floorPrice), 3)}</span>
            </div>
            <div className='btns'>
              <LoadingButton text='Trade' size='large'    onClick={() => navigate(`/market/token/${item.tick}`)}/>
            </div>

          </div>
        )})
      }
    </InfiniteScrollWrapper>
  </Wrapper>
}

export default MobileTable
