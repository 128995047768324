import React from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core'
import styled from 'styled-components'
import LineProgress from '../LineProgress'
import Button from '../Button'
import { useNavigate } from 'react-router-dom'
import {formatEther, numToLocaleString, shortenStr, timestampToTime} from '../../helpers/utils'
import VerifyIcon from '../../images/verified-badge.svg'
import CONFIG from '../../config'
import BitcoinIcon from '../../images/bitcoin-icon.svg'
import InfiniteScroll from 'react-infinite-scroll-component'
import { InfiniteScrollWrapper, TableWrapper, useStyles } from '../Table/Table'
import { OrderedItem } from '../../pages/Token/Token'
import { DEFAULT_NETWORK, NETWORK_CONFIG } from '../../web3/chain'
import toast from '../Toast/Toast'
import { useClipboard } from 'use-clipboard-copy'
import { BigNumber } from 'ethers'




export interface MaterialTableProps {
  data: {
    list: OrderedItem[],
    hasMore: boolean,
    nextFunc: () => void,
  }
}

const OTable: React.FC<MaterialTableProps> = ({ data }) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const {list, hasMore, nextFunc} = data
  const clipboard = useClipboard()

  const handleCopy = (value: string) => {
    if (clipboard.isSupported()) {
      clipboard.copy(value)
      toast({ text: 'copy success' })
    }
  }

  return (
    <TableWrapper>
      <TableContainer component={Paper}>
      <InfiniteScrollWrapper
            dataLength={list.length}
            next={nextFunc}
            hasMore={hasMore}
            height={670}
            loader={null}
            endMessage={null}
          >
        <Table className={classes.table} stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeaderCell}>Order ID</TableCell>
              <TableCell className={classes.tableHeaderCell}>Event</TableCell>
              <TableCell className={classes.tableHeaderCell}>Hash</TableCell>
              <TableCell className={classes.tableHeaderCell}>Amount</TableCell>
              <TableCell className={classes.tableHeaderCell}>Total Price(BTC)</TableCell>
              <TableCell className={classes.tableHeaderCell}>Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.length === 0 && <TableRow>
                <TableCell colSpan={6}><div className='no-data'>No Data</div></TableCell>
              </TableRow>}
            {list.length > 0 && list.map(row => {
              return <TableRow key={row.orderId} className={classes.tableRow}>
                <TableCell className={classes.tableCell} onClick={() => handleCopy(row.orderId)}>{shortenStr(row.orderId, 4, 4)}</TableCell>
                <TableCell className={classes.tableCell}>{row.event}</TableCell>
                <TableCell className={classes.tableCell} onClick={() => window.open(`${NETWORK_CONFIG[DEFAULT_NETWORK].explorer}tx/${row.hash}`)}
                >{shortenStr(row.hash, 4, 4)}</TableCell>
                <TableCell className={classes.tableCell}>{row.amount}</TableCell>
                <TableCell className={classes.tableCell}>{formatEther(BigNumber.from(row.total))}</TableCell>
                <TableCell className={classes.tableCell}>{timestampToTime(row.txTimestamp)}</TableCell>

              </TableRow>
            })}
          </TableBody>
        </Table>
        </InfiniteScrollWrapper>
      </TableContainer>
    </TableWrapper>
  )
}

export default OTable
