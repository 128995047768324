import { Modal as MModal } from '@mui/material'
import Fade from '@mui/material/Fade'
import { useContext } from 'react'
import styled from 'styled-components'
import { ThemeContext } from '../../helpers/hooks'
import { defaultTheme, flexCenter } from '../../style'
import CloseDark from '../../images/close-dark-icon.svg'
import CloseLight from '../../images/close-light-icon.svg'

const ModalBody = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background:  #393939;
  outline: 0;
  border-radius: 12px;
  border: 1px solid #fff;
`
const ModalHeader = styled.div`
  ${flexCenter};
  position: relative;
  padding: 32px 20px;
  font-weight: 700;
  font-family: Inter;
  color: #fff;
  font-size: ${defaultTheme.fontLarge};
  .icon {
    position: absolute;
    right: 24px;
    top: 24px;
    width: 40px;
    height: 40px;
    cursor: ${prop => (prop.theme.isDesktop ? 'pointer' : 'none')};
  }
`

interface ModalProps {
  children: React.ReactElement
  title: string
  open: boolean
  showCloseIcon?: boolean
  width?: number // px
  onClose: () => void
}

const Modal: React.FC<ModalProps> = ({
  children,
  title,
  showCloseIcon = true,
  open,
  width = 0,
  onClose,
}) => {

  return (
    <MModal
      open={open}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Fade in={open}>
        <ModalBody style={{ width: (width ?? 0) > 0 ? `${width}px` : 'auto' }}>
          <ModalHeader>
            {title}
            {showCloseIcon && (
              <img className="icon" src={CloseDark} onClick={onClose} />
            )}
          </ModalHeader>
          {children}
        </ModalBody>
      </Fade>
    </MModal>
  )
}

export default Modal
