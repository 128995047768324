import styled from "styled-components"
import { CheckBoxWrapper } from "../Main/Main"
import RefreshIcon from '../../images/refresh.svg'
import { defaultTheme, flexCenter } from "../../style"
import { useCallback, useEffect, useMemo, useState } from "react"
import CONFIG from "../../config"
import { getMethod, postMethod } from "../../http"
import { useAccount, useBalance, useContractRead, useContractWrite, useNetwork, useSendTransaction, useTransaction } from "wagmi"
import toast from "../../components/Toast/Toast"
import { formatEther, numToLocaleString, parseEther, stringToHex, toDecimalNum, toFloor } from "../../helpers/utils"
import Button from "../../components/Button"
import Modal from "../../components/Modal"
import VerifyIcon from '../../images/verified-badge.svg'
import Input from "../../components/Input"
import LoadingButton from "../../components/LoadingButton"
import { isChainAllowed } from "../../web3/chain"
import { useAppSelector } from "../../helpers/hooks"
import MarketABI from '../../web3/abi/Market.json'
import { InfiniteScrollWrapper } from "../../components/Table/Table"

const Wrapper = styled.div`
display: flex;
height: ${props => props.theme.isDesktop ? 'calc(100vh - 101px)' : 'calc(100vh - 91px)'};
width: 100%;
align-items: center;
margin: 0 auto;
overflow-y: auto;
.title {
  color: #DADADA;
  text-align: center;
  font-family: Inter;
  font-size: ${props => props.theme.isDesktop ? '50px' : '22px'};
  font-weight: 900;
  text-transform: uppercase;
  margin-top: ${props => props.theme.isDesktop ? '60px' : '30px'};
  margin-bottom: ${props => props.theme.isDesktop ? '30px' : '20px'};
}
.options {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${props => props.theme.isDesktop ? '26px 64px' : '20px'} ;
  width: ${props => props.theme.isDesktop ? '1165px' : '100%'};
  .refresh-icon {
    cursor: ${props => props.theme.isDesktop ?  'pointer': 'none'}
    margin-left: 30px;
    width: 24px;
    height: 24px;
  }
}
`

const ListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 1100px;
  ${props => !props.theme.isDesktop && `
    width: calc(100vw - 40px);
  `};
  .no-data {
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    text-align: center;
    color: #fff;
    margin-top: 20px;
    width: 100%;
  }
  .item {
    ${flexCenter};
    justify-content: flex-start;
    border-radius: 25px;
    border: 1px solid #007B6E;
    width: 235px;
    height: 235px;
    flex-shrink: 0;
    margin-right: 50px;
    margin-bottom: 45px;
    ${props => !props.theme.isDesktop && `
      width: calc(50% - 10px);
      margin-right: 10px;
      margin-bottom: 20px;
    `};
    .name {
      width: 100%;
      color: #64D6AC;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: capitalize;
      text-align: center;
      margin-top: 12px;
    }
    .content {
      color: #FFF;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      text-transform: capitalize;
      word-break: break-all;
    }
    .footer {
      ${flexCenter};
      flex-direction: row;
      width: 100%;
      padding-top: 56px;
      color: #FFF;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      margin-bottom: 20px;
      ${props => !props.theme.isDesktop && `
        flex-direction: column;
        margin-bottom: 0px;
      `};
      .main {
        color: ${defaultTheme.bgColor};
        font-weight: 700;
        ${props => !props.theme.isDesktop && `
       margin-top: 10px;
      `};
      }
    }
    button {
      margin-top: 10px;
      border-radius: 10px;
      height: 36px;
      width: 165px;
      ${props => !props.theme.isDesktop && `
      width: 85px;
     `};
    }
  }
`
export const ModalContent = styled.div`
  ${flexCenter};
  padding-bottom: 20px;
  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 40px;
    margin-bottom: 34px;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    ${props => !props.theme.isDesktop && `
    padding: 0 20px;
    margin-bottom: 30px;
   `};
    .label {
      color: #FFF;
    }
    .xvb {
      color: #FFF;
      font-size: 16px;
      font-weight: 400;
    }
    .main {
      color: #64D6AC;
    }
    .value {
      color: #64D6AC;
      margin-right: 10px;
      margin-left: auto;
    }
    .icon {
      width: 27px;
      height: 27px;
    }
    input {
      width: 288px;
    }
    .input-wrapper {
      position: relative;
      input {
        width: 248px;
      }
      .max {
        ${flexCenter};
        position: absolute;
        top: 4px;
        right: 10px;
        width: 48px;
        height: 44px;
        flex-shrink: 0;
        border-radius: 10px;
        border: 1px solid #797979;
        background: #000;
        color: #797979;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
        cursor: ${props => props.theme.isDesktop ?  'pointer': 'none'};
        z-index: 3;
      }
    }
  }
  .row-wrap {
    flex-wrap: wrap;
    .label {
      margin-bottom: 10px;
    }
    .input-wrapper {
      input {
        width: 178px;
      }
    }
  }
  button {
    width: 235px!important;
    height: 61px!important;
  }
`
const TOKENS = 'Tokens'
const RUNES = 'Runes'
const TRANSFER = 'Transfers'
const LIST = 'List'
interface Item {
  address: string
  tick: string
  balance: string
}
type CurrentItem = Item & {
  type: string
}
const Personal: React.FC = () => {
  const [status, setState] = useState(RUNES)
  const { address } = useAccount()
  const [list, setList] = useState<Item[]>([])
  const [currentToken, setCurrentToken] = useState<CurrentItem | null>(null)
  const [toAddress, setToAddress] = useState('')
  const [amount, setAmount] = useState(1)
  const [loading, setLoading] = useState(false)
  const { sendTransaction, data, error } = useSendTransaction()
  const { chain } = useNetwork()
  const isDesktop = useAppSelector(state => state.app.isDesktop)
  const result = useTransaction({
    hash: data?.hash
  })
  const [hasMore, sethasMore] = useState(true)
  const [page, setPage] = useState(1)
  const [btcPrice, setbtcPrice] = useState(42798)
  const [totalPrice, setTotalPrice] = useState<number>()
  const [feeRate, setFeeRate] = useState(0)
  const { write, data: listedData, error: listError } = useContractWrite({
    abi: MarketABI,
    address: CONFIG.MARKET_ADDRESS as '0x',
    functionName: 'list',
  })
  const listResult = useTransaction({
    hash: listedData?.hash
  })
  const { data: info } = useContractRead({
    abi: MarketABI,
    address: CONFIG.MARKET_ADDRESS as '0x',
    functionName: 'getInfo',
  })

  useEffect(() => {
    if (!address) return
    getList(page)
    getBTCPrice()
  }, [address])

  useEffect(() => {
    if (!info) return
    const infoList: any = info
    const fee = Number(infoList[1]) / Number(infoList[2])
    setFeeRate(fee * 100)
  }, [info])

  const getList = (index: number) => {
    if (index !== 1 && !hasMore) return
    postMethod(`${CONFIG.URL}/getUserTicks`, {address, page: index, size: 10}).then(res => {
      const {data: resData} = res
      if (!resData) {
        toast({text: res.message, type: 'error'})
        return
      }
      const {userTicks, pageDetial} = resData
      const {totalSize, currentPage} = pageDetial
      if (index === 1) {
        setList([...userTicks])
      } else {
        setList([...list, ...userTicks])
      }
      sethasMore(totalSize > currentPage * 10)
      setPage(currentPage)
    })
  }


  useEffect(() => {
    if (!result.isSuccess || !loading) return
    setLoading(false)
    toast({text: 'Transfer Successfully'})
    setCurrentToken(null)
    getList(page)
  }, [result, loading])


  useEffect(() => {
    if (!listResult.isSuccess || !loading) return
    setLoading(false)
    toast({text: 'List Successfully'})
    setCurrentToken(null)
    getList(page)
  }, [listResult, loading])

  useEffect(() => {
    if (!error && !listError) return
    setLoading(false)
     // @ts-ignore next line
     toast({text: error?.details || listError?.details, type: 'error'})
  }, [error, listError])

  const handleTXData = (to: string, num: number, token: Item) => {
    const ins = `{"p":"blr-20","op": "transfer","tick":"${token.tick}","amt": "${num}","to": "${to}"}`
    return `0x${stringToHex(`data:,${ins}`)}` as '0x'
  }

  const nextFunc = () => {
    getList(page + 1)
  }

  const getBTCPrice = () => {
    postMethod(`${CONFIG.URL}/getBTCPrice`, {}).then(res => {
      setbtcPrice(Number(res.data.price))
    })
  }

  const handleTransfer = useCallback(() => {
    if (!currentToken || loading) return
    setLoading(true)
    const txData = handleTXData(toAddress, amount, currentToken)
    sendTransaction({
      to: CONFIG.TO,
      data: txData
    })
  }, [currentToken, loading, toAddress, amount, sendTransaction])

  const handleList = () => {
    if (!currentToken || !totalPrice || loading || !amount) return
    setLoading(true)
    write({
      args: [currentToken.tick, Number(amount), parseEther(totalPrice)]
    })
  }

  const disabled: boolean = useMemo(() => {
    return !address || !isChainAllowed(chain?.id) || !toAddress || toAddress === address
  }, [address, chain, toAddress])

  const listDisabled: boolean = useMemo(() => {
    return !address || !isChainAllowed(chain?.id) || !totalPrice
  }, [address, chain, totalPrice])


  const price = useMemo(() => {
    if (!totalPrice || !btcPrice) return '0.00'
    return toFloor(toDecimalNum(formatEther(parseEther(totalPrice).mul(parseEther(btcPrice)))))
  }, [totalPrice, btcPrice])

  return <Wrapper>
    <div className="title">My RUNES</div>
    <div className="options">
      <CheckBoxWrapper>
        {[RUNES].map(item => (
          <div
            key={item}
            className={status === item ? 'item current' : 'item'}
            onClick={() => setState(item)}
          >
            {item}
          </div>
        ))}
      </CheckBoxWrapper>

        <img className="refresh-icon" onClick={() => getList(1)} src={RefreshIcon} />
    </div>

    <InfiniteScrollWrapper
        dataLength={list.length}
        next={nextFunc}
        hasMore={hasMore}
        height={670}
        loader={null}
        endMessage={null}
      >
      <ListWrapper>
        {!list.length && <div className="no-data">No Data</div>}
        {
          !!list.length && list.map(item => (
            <div className="item" key={item.tick}>
            <div className="name">{item.tick}</div>
              <div className="footer">
                <span>Balance:</span>
                <span className="main">{numToLocaleString(item.balance)}</span>
              </div>
              <Button text="Transfer" onClick={() => setCurrentToken({...item, type: TRANSFER})}/>
              <Button text="List" onClick={() => setCurrentToken({...item, type: LIST})}/>
          </div>
          ))
        }
      </ListWrapper>
    </InfiniteScrollWrapper>
      <Modal
        open={!!currentToken && currentToken.type ===  TRANSFER}
        width={isDesktop ? 520 : 300}
        title="Transfer"
        showCloseIcon={false}
        onClose={() => setCurrentToken(null)}
      >
        <ModalContent>
          <div className="row">
            <div className="label">Tick</div>
            <div className="value">{currentToken?.tick}</div>
            {CONFIG.verifyRune === currentToken?.tick &&  <img src={VerifyIcon} className="icon" />}
          </div>
          <div className={isDesktop ? "row" : "row row-wrap"}>
            <div className="label">To</div>
            <Input placeholder="Enter address" value={toAddress} setValue={setToAddress}/>
          </div>
          <div className={isDesktop ? "row" : "row row-wrap"}>
            <div className="label">Amount</div>
            <div className="input-wrapper">
              <Input
              type="number" onlyInt
              placeholder="Enter The Amount"
              value={amount}
              min="1"
              max={currentToken?.balance}
              setValue={setAmount}/>
              <div className="max" onClick={() => setAmount(Number(currentToken?.balance))}>Max</div>
            </div>
          </div>
          <LoadingButton disabled={disabled} size="large" loading={loading} text="Transfer" onClick={handleTransfer}/>
        </ModalContent>
      </Modal>
      <Modal
        open={!!currentToken && currentToken.type === LIST}
        width={isDesktop ? 520 : 300}
        title={`List ${currentToken?.tick} For Sale`}
        showCloseIcon={false}
        onClose={() => setCurrentToken(null)}
      >
        <ModalContent>
          <div className="row">
            <div className="label">Tick</div>
            <div className="value">{currentToken?.tick}</div>
            {CONFIG.verifyRune === currentToken?.tick &&  <img src={VerifyIcon} className="icon" />}
          </div>

          <div className={isDesktop ? "row" : "row row-wrap"}>
            <div className="label">Amount</div>
            <div className="input-wrapper">
              <Input
              type="number" onlyInt
              placeholder="Enter The Amount"
              value={amount}
              min="1"
              max={currentToken?.balance}
              setValue={setAmount}/>
              <div className="max" onClick={() => setAmount(Number(currentToken?.balance))}>Max</div>
            </div>
          </div>
          <div className="row" style={{"marginTop": "-30px"}}>
            <div className="xvb">Balance</div>
            <div className="xvb main">{currentToken?.balance}</div>
          </div>
          <div className={isDesktop ? "row" : "row row-wrap"}>
            <div className="label">Total Price</div>
            <div className="input-wrapper">
              <Input
              type="number"
              placeholder="Enter the Price"
              value={totalPrice}
              min="1"
              maxLength={12}
              setValue={setTotalPrice}/>
            </div>
          </div>
          <div className="row">
            <div className="xvb">Total Price</div>
            <div className="xvb main">{totalPrice} BTC ≈ ${price}</div>
          </div>
          <div className="row">
            <div className="xvb">Fee</div>
            <div className="xvb">{feeRate}%</div>
          </div>
          <LoadingButton disabled={listDisabled} size="large" loading={loading} text="List" onClick={handleList}/>
        </ModalContent>
      </Modal>
  </Wrapper>
}

export default Personal
