import { gradient } from '../../style'
import LinearProgress from '@mui/material/LinearProgress'
import { makeStyles } from '@mui/styles'
import styled from 'styled-components'

interface Props {
  progress: number
  size?: string
}

const LinearProgressWrapper = styled(({ size: string, ...arg }) => <LinearProgress {...arg} />)`
  height: ${props => props.size === 'large' ? '30px' : '18px'};
  width: ${props => props.size === 'large' ? '100%' : '150px'};
  border-radius: 32px;
  background: #fff;
  border: 2px solid ${props => props.theme.bgColor};;
  .MuiLinearProgress-bar {
    border-radius: 28px;
    background: ${gradient};
  }
`


const LineProgress: React.FC<Props> = ({ progress, size }) => {
  // const classes = useStyles({size})

  return (
    <LinearProgressWrapper
      size={size}
      variant="determinate"
      value={progress}
    />
  )
}

export default LineProgress
