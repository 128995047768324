import React from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core'
import styled from 'styled-components'
import LineProgress from '../LineProgress'
import Button from '../Button'
import { useNavigate } from 'react-router-dom'
import { TokenItem } from '../../pages/Main/Main'
import {numToLocaleString} from '../../helpers/utils'
import VerifyIcon from '../../images/verified-badge.svg'
import CONFIG from '../../config'
import InfiniteScroll from 'react-infinite-scroll-component'
import { scrollbar } from '../../style'

export const TableWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 1116px;
  border-radius: 25px;
  border: 1px solid #c5c5c5;
  height: 676px;
  flex-shrink: 0;
  background: linear-gradient(112deg, #393939 -4.38%, rgba(0, 0, 0, 0.47) 91.02%);
  margin-bottom: 40px;

  .MuiPaper-root {
    color: #c1c1c1;
    border-radius: 25px;
    background: transparent;
    box-shadow: none;
  }
  .MuiTableCell-root {
    border-bottom: 0;
  }
  .progress-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .row {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    .icon {
      width: 20px;
      margin-left: 10px;
    }
    .coin-icon {
      width: 22px;
      height: 22px;
      margin-right: 10px;
    }
    .unit {
      margin-left: 4px;
    }
  }
  .no-data {
    color: #fff;
    text-align: center;
  }

`

export const InfiniteScrollWrapper = styled(({...args}) => <InfiniteScroll {...args} />)`
  overflow-y: auto;
  ${scrollbar};
`

export const useStyles = makeStyles(() =>
  createStyles({
    table: {
      minWidth: 650,
      maxHeight: 676,
    },
    tableHeaderCell: {
      color: `#C1C1C1`,
      fontWeight: 700,
      fontFamily: `Inter`,
      border: '0px',
      textAlign: 'center',
      background: '#000'
    },
    tableRow: {
      fontFamily: `Inter`,
    },
    tableCell: {
      color: `#fff`,
      fontWeight: 700,
      fontSize: 18,
      fontFamily: `Inter`,
      textAlign: 'center',
      border: '0px',
    },
  }),
)

export interface MaterialTableProps {
  data: {
    list: TokenItem[],
    hasMore: boolean,
    nextFunc: () => void,
  }
}

const RTable: React.FC<MaterialTableProps> = ({ data }) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const {list, hasMore, nextFunc} = data

  return (
    <TableWrapper>
      <TableContainer component={Paper}>
      <InfiniteScrollWrapper
            dataLength={list.length}
            next={nextFunc}
            hasMore={hasMore}
            height={670}
            loader={null}
            endMessage={null}
          >
        <Table className={classes.table} stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeaderCell}>Number</TableCell>
              <TableCell className={classes.tableHeaderCell}>Name</TableCell>
              <TableCell className={classes.tableHeaderCell}>Process</TableCell>
              <TableCell className={classes.tableHeaderCell}>Supply</TableCell>
              <TableCell className={classes.tableHeaderCell}>Mint%</TableCell>
              <TableCell className={classes.tableHeaderCell}>Holders</TableCell>
              <TableCell className={classes.tableHeaderCell}>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {list.length === 0 && <TableRow>
                <TableCell colSpan={6}><div className='no-data'>No Data</div></TableCell>
              </TableRow>}
            {list.length > 0 && list.map((row, index) => {
              const mintPre = Number(row.currentSupply) / Number(row.max) * 100
              const pre = mintPre.toFixed(2) <= '0.00' ? '0.01' : mintPre.toFixed(2)
              return <TableRow key={row.hash} className={classes.tableRow}>
                <TableCell className={classes.tableCell}>{index + 1}</TableCell>
                <TableCell className={classes.tableCell}>
                  <div className="row">
                    <>{row.tick}</>
                    {CONFIG.verifyRune === row.tick &&  <img src={VerifyIcon} className="icon" />}
                  </div>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <div className='progress-wrapper'>
                    <LineProgress progress={mintPre} />
                  </div>
                </TableCell>
                <TableCell className={classes.tableCell}>{numToLocaleString(row.max)}</TableCell>
                <TableCell className={classes.tableCell}>{mintPre === 0 ? 0 : pre}%</TableCell>
                <TableCell className={classes.tableCell}>{numToLocaleString(row.holders)}</TableCell>
                {/* <TableCell className={classes.tableCell}>{Number(row.currentSupply)  / Number(row.lim)}</TableCell> */}
                <TableCell className={classes.tableCell}>
                  <Button
                    disabled={Number(row.max) === Number(row.currentSupply) || row.isSeal}
                    text='Mint'
                    onClick={() => navigate(`/mint/${row.tick}`)}/>
                </TableCell>
              </TableRow>
            })}
          </TableBody>
        </Table>
        </InfiniteScrollWrapper>
      </TableContainer>
    </TableWrapper>
  )
}

export default RTable
