import { createContext } from 'react'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { matchRoutes, useLocation } from 'react-router-dom'
import { AppDispatch, AppState } from '../redux/store'
import routes from '../routerConfig'

interface Theme {
  darkMode: boolean
  setDarkMode: React.Dispatch<React.SetStateAction<boolean>>
}

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const ThemeContext = createContext({} as Theme)

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector
export const useCurrentPath = () => {
  const location = useLocation()
  const [{ route }] = matchRoutes(routes, location)!
  return route.path || ''
}
