import styled from "styled-components"
import Logo from '../../components/Header/logo.svg'
import BGIMG from './logo.svg'
import BG from './looper-bg.svg'
import GROUP from './group.svg'
import FRAME_1 from './frame-1.svg'
import FRAME_2 from './frame-2.svg'
import FRAME_3 from './frame-3.svg'
import FRAME_4 from './frame-4.svg'
import ARROW from './arrow.svg'
import { defaultTheme, flexCenter } from "../../style"
import { Link } from "react-router-dom"
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import { Navigation, Pagination } from 'swiper/modules'
import { useRef } from "react"


const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin: 0 auto;
  overflow-y: auto;
  overflow-x: hidden;
  .header {
    ${flexCenter};
    flex-direction: row;
    margin-top: 20px;
    margin-bottom: 70px;
    .logo {
      width: auto;
      height: 40px;
      margin-right: 10px;
      ${props => !props.theme.isDesktop && `
        height: 25px;
      `}
    }
  }
  .looper-bg {
    position: absolute;
    left: 230px;
    top: 560px;
    transform: rotate(15deg);
    flex-shrink: 0;
    ${props => !props.theme.isDesktop && `
    display: none;
`}
  }
  .bg {
    position: absolute;
    left: calc(50% - 767.872px / 2);
    top: 70px;
    width: 767.872px;
    height: 686.351px;
    flex-shrink: 0;
    opacity: 0.5;
    filter: blur(24px);
    ${props => !props.theme.isDesktop && `
      width: 267px;
      height: 300px;
      left: calc(50% - 267px / 2);
      top: 80px;
  `}
  }
  .main {
    ${flexCenter};
    z-index: 1;
    margin-top: 140px;
    ${props => !props.theme.isDesktop && `
    margin-top: 40px;
  `}
    .main-title {
      font-family: Inter;
      font-size: 80px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 1.6px;
      background: linear-gradient(90deg, #007B6E 0%, #64D6AC 50%, #007B6E 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      ${props => !props.theme.isDesktop && `
      font-size: 36px;
    `}
    }
    .title {
      ${flexCenter};
      flex-direction: row;
      width: 100%;
      color: #FFF;
      font-family: Inter;
      font-size: 80px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 1.6px;
      ${props => !props.theme.isDesktop && `
        font-size: 28px;
      `}
      .b {
        color: ${props => props.theme.bgColor};
        margin: 0 8px;
      }
    }
    .launch {
      display: flex;
      width: 218px;
      height: 70px;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      border-radius: 50px;
      border: 2px solid #FAFAFA;
      background: #000;
      color: #FFF;
      text-align: center;
      font-family: Space Grotesk;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 20px;
      :hover {
        border-color: ${defaultTheme.bgColor};
        color: ${defaultTheme.bgColor};
      }
      ${props => !props.theme.isDesktop && `
      width: 250px;
      height: 50px;
      margin-top: 120px;
  `}
    }
  }
  .content-wrapper {

    .container {
      ${flexCenter};
      position: relative;
      border-radius: 24px;
      border: 2px solid #FFF;
      padding: 64px 44px;
      width: 1120px;
      margin-top: 340px;
      background: linear-gradient(208deg, rgba(0, 123, 110, 0.30) 9.05%, rgba(100, 214, 172, 0.30) 76.74%);
      ${props => !props.theme.isDesktop && `
      width: calc(100% - 40px);
      padding: 44px 24px;
      margin: 0 auto;
      margin-top: 200px;
  `}
      .title {
        color: #FFF;
        font-family: Inter;
        font-size: 60px;
        font-style: normal;
        font-weight: 700;
        line-height: 48px; /* 80% */
        text-transform: uppercase;
        margin-bottom: 30px;
        ${props => !props.theme.isDesktop && `
        font-size: 28px;
        margin-bottom: 15px;
    `}
      }
      .content {
        color: rgba(255, 255, 255, 0.90);
        text-align: center;
        font-family: Inter;
        font-size: 26px;
        font-style: normal;
        font-weight: 300;
        line-height: 40px;
        ${props => !props.theme.isDesktop && `
        font-size: 18px;
        line-height: 28px;
    `}
      }
      .group-right {
        width: 130px;
        position: absolute;
        right: -60px;
        top: -20px;
        ${props => !props.theme.isDesktop && `
        width: 70px;
        right: -20px;
        top: -20px;
    `}
      }
      .group-left {
        width: 130px;
        position: absolute;
        left: -40px;
        bottom: 20px;
        ${props => !props.theme.isDesktop && `
        width: 70px;
        left: -20px;
        bottom: 10px;
    `}
      }
    }
  }
  .index-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 996px;
    margin-top: 400px;
    ${props => !props.theme.isDesktop && `
    margin-top: 100px;
    width: calc(100% - 40px);
`}
    .item {
      ${flexCenter};
      width: 448px;
      height: 261px;
      flex-shrink: 0;
      border-radius: 20px;
      border: 2px solid #FFF;
      background: rgba(1, 124, 111, 0.31);
      color: #FFF;
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      text-align: center;
      line-height: 56px; /* 233.333% */
      margin-bottom: 70px;
      .icon {
        height: 80px;
        width: auto;
        margin-bottom: 20px;
      }
      ${props => !props.theme.isDesktop && `
      font-size: 18px;
      width: 100%;
      height: 220px;
      margin-bottom: 30px;
  `}
    }
  }
  .roadmap-wrapper {
    ${flexCenter}
    margin-top: 320px;
    margin-bottom: 120px;
    ${props => !props.theme.isDesktop && `
    margin-top: 120px;
    margin-bottom: 70px;
`}
    .title {
      color: #FFF;
      font-family: Inter;
      font-size: 60px;
      font-style: normal;
      font-weight: 700;
      line-height: 48px; /* 80% */
      text-transform: uppercase;
      ${props => !props.theme.isDesktop && `
      font-size: 28px;
  `}
    }
    .my-swiper {
      position: relative;
      .pre {
        position: absolute;
        left: -200px;
        top: calc(50% + 58px);
        width: 116px;
        height: 116px;
        cursor: ${prop => (prop.theme.isDesktop ? 'pointer' : 'none')};
        @media (min-width: 1024px) and (max-width: 1440px) {
          width: 76px;
          height: 76px;
        }
        ${props => !props.theme.isDesktop && `
        width: 36px;
        height: 36px;
        top: 30px;
        left: 20px;
    `}
      }
      .next {
        position: absolute;
        right: -200px;
        top: calc(50% + 58px);
        width: 116px;
        height: 116px;
        transform: rotate(180deg);
        cursor: ${prop => (prop.theme.isDesktop ? 'pointer' : 'none')};
        @media (min-width: 1024px) and (max-width: 1440px) {
          width: 76px;
          height: 76px;
        }
        ${props => !props.theme.isDesktop && `
        width: 36px;
        height: 36px;
        top: 30px;
        right: 20px;
    `}
      }
    }

  }
`
const RSwiperSlide = styled(SwiperSlide)`
    ${flexCenter};
    display: flex !important;
    border-radius: 20px;
    border: 2px solid #FFF;
    background: rgba(1, 124, 111, 0.31);
    color: #FFF;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 56px;
    ${props => !props.theme.isDesktop && `
    font-size: 16px;
    line-height: 24px;
`}
    .index {
      ${flexCenter};
      position: absolute;
      top: 20px;
      left: calc(50% - 30px);
      color: #fff;
      width: 60px;
      height: 60px;
      background-color: ${props => props.theme.bgColor};
      border-radius: 50%;
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      border: 2px solid #FFF;
      ${props => !props.theme.isDesktop && `
      font-size: 16px;
      line-height: 24px;
      width: 30px;
      height: 30px;
      top: 10px;
      left: calc(50% - 15px);
  `}
    }
    .content {
      position: relative;
      width: 100%;
      text-align: left;
      padding: 0 120px;
      ${props => !props.theme.isDesktop && `
      width:  auto;
      padding: 0 30px;
      margin-bottom: 12px;
    `}
      :before {
        position: absolute;
        top: 24px;
        left: 80px;
        content: "";
        border-radius: 50%;
        width: 6px;
        height: 6px;
        background: #FFF;
        ${props => !props.theme.isDesktop && `
        top: 10px;
        left: 10px;
    `}
      }
    }
`
const RSwiper = styled(Swiper)`
  width:  1200px;
  height: 600px;
  margin-top: 200px;
  @media (min-width: 1024px) and (max-width: 1440px) {
    width:  800px;
    height: 400px;
  }
    ${props => !props.theme.isDesktop && `
    width:  300px;
    height: 280px;
    margin-top: 80px;
  `}
  .swiper-wrapper {
    display: flex !important;
    flex-direction: row !important;
  }

`

const Home: React.FC = () => {
  const swiperRef = useRef<any>()

  const pagination = {
    clickable: true,
    renderBullet: (index: number) => {
      return <span>{(index + 1)}</span>
    },
  }


  return <Wrapper>
    <img src={BG} className="looper-bg" alt="BG"/>
    <img src={BGIMG} className="bg" alt="BG"/>
    <div className="header">
     <img className="logo" src={Logo} alt="bl2rune" />
    </div>
    <div className="main">
      <div className="main-title">BL2RUNE</div>
      <div className="title">
        <span>The First</span>
        <span className="b">Rune</span>
        <span>On BEVM</span>
      </div>
      <Link to="/tokens">
        <div className="launch">Launch</div>
      </Link>
    </div>
    <div className="content-wrapper">
    <div className="container">
      <div className="title">About BL2RuNE</div>
      <div className="content">
        {`BL2Rune is a rune launch platform built on the BEVM. Leveraging the BEVM's virtual machine and decentralized application layer, it establishes new standards for runes. Additionally, with the high performance of the EVM, BL2Rune accommodate a broader user base. In the future, all runes launched on the BL2Rune platform will seamlessly cross assets through the BEVM's cross-chain bridge.`}
      </div>
      <img className="group-right" src={GROUP} alt="group" />
      <img className="group-left" src={GROUP} alt="group" />
    </div>
    </div>
    <div className="index-wrapper">
      <div className="item">
        <img src={FRAME_1} className="icon" alt="FRAME" />
        <span>Robust Index System</span>
      </div>
      <div className="item">
        <img src={FRAME_2} className="icon" alt="FRAME" />
        <span>Mature Trading Marketplace</span>
      </div>
      <div className="item">
        <img src={FRAME_3} className="icon" alt="FRAME" />
        <span>Extremely Low Gas Consumption</span>
      </div>
      <div className="item">
        <img src={FRAME_4} className="icon" alt="FRAME" />
        <span>Enhanced Blockchain Performance</span>
      </div>
    </div>
    <div className="roadmap-wrapper">
      <div className="title">Our roadmap</div>
      <div className="my-swiper">
        <img src={ARROW} className="pre" alt="ARROW" onClick={() => swiperRef.current?.slidePrev()}/>
        <img src={ARROW} className="next" alt="ARROW" onClick={() => swiperRef.current?.slideNext()}/>

      <RSwiper
        spaceBetween={50}
        effect={'fade'}
        navigation
        onSwiper={(swiper: any) => {
          swiperRef.current = swiper
        }}
        pagination={pagination}
        modules={[Navigation, Pagination]}>
        <RSwiperSlide>
            <div className="index">1</div>
            <div className="content">Launch of the BL2Rune platform</div>
            <div className="content">Completion of minting and deployment functionalities</div>
            <div className="content">Introduction of the index system</div>
        </RSwiperSlide>
        <RSwiperSlide>
          <div className="index">2</div>
          <div className="content">Opening of the BL2Rune trading marketplace</div>
          <div className="content">Support for batch buying and selling</div>
        </RSwiperSlide>
        <RSwiperSlide>
        <div className="index">3</div>
          <div className="content">Implementation of cross-chain functionality</div>
          <div className="content">Runes can be seamlessly moved to new blockchains through the BEVM cross-chain bridge</div>
        </RSwiperSlide>
        <RSwiperSlide>
        <div className="index">4</div>
          <div className="content">To be determined</div>
        </RSwiperSlide>
      </RSwiper>
      </div>
    </div>
  </Wrapper>
}

export default Home
