import React, { useState, useEffect, useMemo } from "react"
import styled from "styled-components"
import { flexCenter } from "../../style"
import BitcoinIcon from '../../images/bitcoin-icon.svg'
import Button from "../../components/Button"
import Modal from "../../components/Modal"
import VerifyIcon from '../../images/verified-badge.svg'
import LoadingButton from "../../components/LoadingButton"
import { LISTED, ListedItem, MYLISTED } from "./Token"
import { formatEther, numToDecimal, numToLocaleString, parseEther, parseSARS, shortenStr, toDecimalNum, toFloor } from "../../helpers/utils"
import CONFIG from "../../config"
import MarketABI from '../../web3/abi/Market.json'
import { useAccount, useBalance, useContractRead, useContractWrite, useTransaction } from "wagmi"
import toast from "../../components/Toast/Toast"
import { BigNumber } from "ethers"
import { DEFAULT_NETWORK, NETWORK_CONFIG } from "../../web3/chain"

const Wrapper = styled.div`
  ${flexCenter};
  position: relative;
  width: 235px;
  height: 318px;
  flex-shrink: 0;
  border-radius: 25px;
  border: 1px solid #007B6E;
  margin: 0 21px 24px 21px;
  padding-top: 13px;
  padding-bottom: 20px;
  .name {
    color: #64D6AC;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
  }
  .num {
    color: #FFF;
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    margin: 20px 0;
  }
  .num-main {
    color: #64D6AC;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 3px;
  }
  .price {
    color: #757575;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 13px;
  }
  .center {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    background: #007B6E;
    padding: 6px 10px;
    margin-bottom: 13px;
    color: #FFF;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    .center-icon {
      width: 22px;
      height: 22px;
      margin-right: 4px;
    }
    .vac {
      margin-left: auto;
      font-weight: 400;
    }
  }
  .col {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 20px;
    width: 100%;
    color: #FFF;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    text-align: center;
    .link {
      text-decoration-line: underline;
      text-transform: capitalize;
      cursor: pointer;
    }
  }
  button {
    width: 165px!important;
    height: 39px;
    border-radius: 10px!important;
  }
  .mask {
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.60);
    width: 100%;
    height: 100%;
  }
`
const MBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 53px 40px 53px;
  .box {
    ${flexCenter};
    border-radius: 25px;
    border: 2px solid #007B6E;
    background: #0E0E0E;
    width: 201px;
    padding: 16px 0;
    margin-bottom: 32px;
    .box-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #64D6AC;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: capitalize;
      .verify-icon {
        width: 26px;
        height: 26px;
        flex-shrink: 0;
        margin-left: 4px;
      }
    }
    .box-content {
      color: #FFF;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: capitalize;
      margin: 27px 0;
    }
    .box-row {
      ${flexCenter};
      flex-direction: row;
      color: #FFF;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;
      .icon {
        width: 22px;
        height: 22px;
        flex-shrink: 0;
        margin-right: 4px;
      }
    }
    .box-price {
      color: #757575;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;
    }
  }
  .m-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 27px;
    width: 100%;
    .label {
      color: #FFF;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: capitalize;
    }
    .label-main {
      color: #64D6AC;
    }
    .label-vc {
      font-weight: 400;
    }
  }
`
interface Props {
  data: ListedItem & {
    btcPrice: number
    type: string
    feeRate: number
    refreshData: () => void
  }
}

export const TokenItem: React.FC<Props> = ({data}) => {
  const [show, setShow] = useState(false)
  const { address } = useAccount()
  const [loading, setLoading] = useState(false)
  const [userBalance, setUserBalance] = useState('')
  const { data: balance } = useBalance({
    chainId: DEFAULT_NETWORK,
    address,
  })

  const { write, data: buyData, error } = useContractWrite({
    abi: MarketABI,
    address: CONFIG.MARKET_ADDRESS as '0x',
    functionName: 'buy',
    value: BigInt(data.total)
  })
  const result = useTransaction({
    hash: buyData?.hash
  })
  const { write: cancelMethod, data: cancelData, error: cancelError } = useContractWrite({
    abi: MarketABI,
    address: CONFIG.MARKET_ADDRESS as '0x',
    functionName: 'unlist',
  })
  const cancelResult = useTransaction({
    hash: cancelData?.hash
  })

  useEffect(() => {
    if (!error && !cancelError) return
    setLoading(false)
    console.error(error, 'error')
    console.error(cancelError, 'cancelError')
    // @ts-ignore next line
    toast({text: error?.details || cancelError?.details, type: 'error'})
  }, [error, cancelError])

  useEffect(() => {
    if (!balance) return
    setUserBalance(balance.formatted)
  }, [balance])

  useEffect(() => {
    if (!data || !result.isSuccess || !loading) return
    setLoading(false)
    data.refreshData()
    toast({text: 'Transfer Successfully'})
  }, [result, data, loading])

  useEffect(() => {
    if (!data || !cancelResult.isSuccess || !loading) return
    setLoading(false)
    data.refreshData()
    toast({text: 'Cancel Successfully'})
  }, [cancelResult, data, loading])

  const handleBuy = () => {
    if (!data || loading || disabled) return
    setLoading(true)
    write({
      args: [data.orderId]
    })
  }
  const handleCancel = () => {
    if (!data || loading) return
    setLoading(true)
    cancelMethod({
      args: [data.orderId]
    })
  }

  const disabled = useMemo(() => {
    if (Number(userBalance) < formatEther(BigNumber.from(data.total))) return true
    return false
  } ,[userBalance, data])

  const fee = useMemo(() => {
    if (!data || !data.feeRate) return 0
    return toDecimalNum(toDecimalNum(formatEther(parseEther(data.total).mul(parseEther(data.feeRate)))))
  }, [data])

  const feePrice = useMemo(() => {
    if (!fee || !data.btcPrice) return 0
    return toFloor(toDecimalNum(toDecimalNum(formatEther(parseEther(data.btcPrice).mul(parseEther(numToDecimal(fee)))))))
  }, [fee, data.btcPrice])

  return (
    <Wrapper>
      <div className="name">{data.tick}</div>
      <div className="num">{numToLocaleString(data.amount)}</div>
      <div className="num-main">{toFloor(parseSARS(data.uintPrice), 3)} Sats / {data.tick}</div>
      <div className="price">${toFloor(toDecimalNum(formatEther(parseEther(data.uintPrice).mul(parseEther(data.btcPrice)))))}</div>
      <div className="center">
        <img src={BitcoinIcon} className="center-icon" />
        <span>{formatEther(BigNumber.from(data.total))}</span>
        <span className="vac">${toFloor(toDecimalNum(toDecimalNum(formatEther(parseEther(data.total).mul(parseEther(data.btcPrice))))))}</span>
      </div>
      <div className="col">
        <div className="">Seller</div>
        <div className="link" onClick={() => window.open(`${NETWORK_CONFIG[DEFAULT_NETWORK].explorer}address/${data.owner}`)}>{shortenStr(data.owner, 4, 4)}</div>
      </div>
        {
          data.type === MYLISTED ? <LoadingButton loading={loading} onClick={handleCancel} text="Cancel"/>
          : <Button text="Buy" onClick={() => setShow(true)}/>
        }

        <Modal
          title="Purchase Confirmation"
          width={520}
          showCloseIcon={false}
          open={show}
          onClose={() => setShow(false)}
        >
        <MBody>
          <div className="box">
            <div className="box-header">
              <span>{data.tick}</span>
              {data.tick === CONFIG.verifyRune && <img src={VerifyIcon} className="verify-icon" />}
            </div>
            <div className="box-content">
              {numToLocaleString(data.amount)}
            </div>
            <div className="box-row">
              <img src={BitcoinIcon} className="icon" />
              <span>{formatEther(BigNumber.from(data.total))}</span>
            </div>
            <div className="box-price">≈ ${toFloor(toDecimalNum(toDecimalNum(formatEther(parseEther(data.total).mul(parseEther(data.btcPrice))))))}</div>
          </div>
          <div className="m-row">
            <div className="label">Tick</div>
            <div className="label label-main">{data.tick}</div>
          </div>
          <div className="m-row">
            <div className="label">Total Price</div>
            <div className="label label-vc">{formatEther(BigNumber.from(data.total))} BTC ≈ ${toFloor(toDecimalNum(toDecimalNum(formatEther(parseEther(data.total).mul(parseEther(data.btcPrice))))))}</div>
          </div>
          <div className="m-row">
            <div className="label">Fee</div>
            <div className="label label-vc">
              {
                `${fee} BTC ≈ $ ${feePrice}`
              }
            </div>
          </div>
          <div className="m-row">
            <div className="label">You Pay</div>
            <div className="label label-main">{formatEther(BigNumber.from(data.total))} BTC ≈ ${toFloor(toDecimalNum(toDecimalNum(formatEther(parseEther(data.total).mul(parseEther(data.btcPrice))))))}</div>
          </div>
          <div className="m-row">
            <div className="label">Available Balance</div>
            <div className="label label-vc">{userBalance || '0.00'} BTC</div>
          </div>
          <LoadingButton loading={loading} disabled={disabled} size="large" onClick={handleBuy} text="Buy" />
        </MBody>
        </Modal>
    </Wrapper>
  )
}
