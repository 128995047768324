import { useContext } from 'react'
import styled from 'styled-components'
import { useAppSelector, useCurrentPath } from '../../helpers/hooks'
import Logo from './logo.svg'
import TW from './twitter.svg'
import { defaultTheme, flexCenter } from '../../style'
import Tab from '../Tab'
import Wallet from '../Wallet'
import { Link } from 'react-router-dom'

const HeaderWrapper = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  z-index: 10;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: ${prop => prop.theme.isDesktop? '30px 65px' : '30px 20px'};
  background: transparent;
  border-bottom: 1px solid #c5c5c5;
  .row {
    ${flexCenter};
    flex-direction: row;
  }
  .icon {
    cursor: ${prop => (prop.theme.isDesktop ? 'pointer' : 'none')};
    width: 30px;
    height: 30px;
    margin: 0 20px;
    ${props => !props.theme.isDesktop && `
    margin-left: auto;
  `}
  }
`
const LogoWrapper = styled.div`
  ${flexCenter};
  flex-direction: row;
  align-items: center;
  color: ${props => props.theme.grey1};
  font-size: ${defaultTheme.fontLarge};
  font-weight: bold;
  cursor: ${prop => (prop.theme.isDesktop ? 'pointer' : 'none')};
  .logo {
    width: auto;
    height: 40px;
    margin-right: 10px;
    ${props => !props.theme.isDesktop && `
      height: 25px;
    `}
  }
`
const TWITTER_URL = 'https://twitter.com/bl2rune'
const Header: React.FC = () => {
  const isDesktop = useAppSelector(state => state.app.isDesktop)
  const currentPath = useCurrentPath()

  if (currentPath === '/') return <></>
  return (
    <HeaderWrapper>
      <Link to="/">
        <LogoWrapper>
          <img className="logo" src={Logo} alt="bl2rune" />
        </LogoWrapper>
      </Link>
      {!isDesktop &&<>
       <img className="icon" onClick={() => window.open(TWITTER_URL)} src={TW} />
       <Wallet />
       </>
      }
      <Tab />
      {
        isDesktop && <div className="row">
        <img className="icon" onClick={() => window.open(TWITTER_URL)} src={TW} />
          <Wallet />
        </div>
      }

    </HeaderWrapper>
  )
}

export default Header
