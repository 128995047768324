import { LoadingButton as MLoadingButton } from '@mui/lab'
import styled from 'styled-components'
import { defaultTheme, flexCenter, gradient } from '../../style'

const ButtonWrapper = styled(MLoadingButton)`
  &.MuiButton-contained {
    border-radius: 20px;
    border: 1px solid #FFF;
    background: linear-gradient(90deg, #007B6E 0%, #64D6AC 100%);
    color: #fcfcfd;
    line-height: 22px;
    font-size: ${defaultTheme.fontNormal};
    padding: 8px 24px;
    text-transform: none;
    width: ${props => (props.fullWidth ? '100%' : 'fit-content')};
    :hover {
      opacity: 0.8;
    }
    &.Mui-disabled {
      ${flexCenter};
      border-radius: 10px;
      border: 1px solid #FFF;
      background: #737373;
      flex-shrink: 0;
      color: #FFF;
      font-family: Inter;
      font-style: normal;
      font-weight: 900;
      letter-spacing: 0.26px;
      cursor: not-allowed;
    }
    &.small {
      line-height: 24px;
      font-size: ${defaultTheme.fontSmall};
      padding: 8px 16px;
      border-radius: 16px;
    }
    &.large {
      border-radius: 10px;
      padding: 14px 100px;
      color: #FFF;
      font-weight: 900;
      font-family: Inter;
      font-size: 26px;
    }
  }
  &.MuiButton-outlined {
    background-color: ${props => props.theme.grey1};
    color: ${props => props.theme.grey1};
    border-color: ${props => props.theme.grey1};
    border-radius: 180px;
    font-weight: 400;
    text-transform: none;
    font-size: ${defaultTheme.fontNormal};
    padding: 8px 24px;
  }
  &.MuiButton-text {
    border: 0;
  }
`

type LoadingButtonProps = {
  loading?: boolean
  disabled?: boolean
  text: string
  size?: 'small' | 'large' | 'medium'
  variant?: 'text' | 'outlined' | 'contained' // 文字型、描边型、实心按钮
  fullWidth?: boolean // 是否根据父元素填充宽度
  loadingPosition?: 'center' | 'end' | 'start'
} & React.ButtonHTMLAttributes<HTMLButtonElement>

const LoadingButton: React.FC<LoadingButtonProps> = ({
  loading,
  disabled,
  text,
  size = 'medium',
  variant = 'contained',
  loadingPosition = 'start',
  fullWidth = false,
  onClick,
}) => {

  const handleClick = (evt: any) => {
    if (onClick && !disabled && !loading) {
      onClick(evt)
    }
  }

  return (
    <ButtonWrapper
      className={size}
      disabled={disabled}
      loading={loading}
      variant={variant}
      onClick={handleClick}
      size={size}
      loadingPosition={loadingPosition}
      fullWidth={fullWidth}
    >
      {text}
    </ButtonWrapper>
  )
}

export default LoadingButton
