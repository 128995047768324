import { createGlobalStyle, DefaultTheme } from 'styled-components'
import '@mui/styles'
import { createTheme } from '@mui/material'
import { isDesktop } from './helpers/utils'

export const DEFAULT_FONT_SIZE = '14px'
export const defalutFont = {
  fontLargest: '40px',
  fontLarge: '24px',
  fontNormal: DEFAULT_FONT_SIZE,
  fontSmall: '12px',
}
export const mobileFont = {
  fontLargest: '0.4rem',
  fontLarge: '0.24rem',
  fontNormal: '0.14rem',
  fontSmall: '0.12rem',
}

let defaultTheme = {
  grey1: '#000',
  grey2: '',
  grey3: '',
  grey4: '',
  grey5: '',
  primaryColor: '#007B6E;',
  borderColor: '',
  bgColor: '#64D6AC',
  errorColor: '#F91818',
  isDesktop,
  ...defalutFont,
}
export const scrollbar = `
  ::-webkit-scrollbar {
    width: 4px;
    height: 10px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 2px;
    background-color: #fff;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #fff;
    border: 1px solid #000;
    border-radius: 12px;
    height: 20px;
  }

`
// init
if (!isDesktop) {
  defaultTheme = { ...defaultTheme, ...mobileFont }
}

const darkTheme = createTheme({}, {
  ...defaultTheme,
} as DefaultTheme)

const lightTheme = createTheme({}, {
  ...defaultTheme,
} as DefaultTheme)

const flexCenter = `
  display: flex;
  justify-content: center;
  align-items: center;
`

const gradient = 'linear-gradient(90deg, #007B6E 0%, #64D6AC 52.08%, #007B6E 100%)'

const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
  }

  body {
    min-height: 100%;
    position: relative;
    font-family: 'Inter', sans-serif;
    font-size: ${prop => prop.theme.fontNormal};

    // fix style: WalletConnect modal
    #walletconnect-qrcode-modal {
      .walletconnect-modal__base {
        top: 24%;
        margin: ${prop => (prop.theme.isDesktop ? 'auto' : 'auto 0')};
      }
      .walletconnect-modal__mobile__toggle {
        display: ${prop => (prop.theme.isDesktop ? 'none' : 'flex')};
        flex-direction: row;
      }
    }
  }

  body, textarea, input, button {
    line-height: 1;
  }

  body, div, p {
    margin: 0;
    padding: 0;
  }

  button, a {
    padding: 0;
    margin: 0;
    border: 0;
    background: transparent;
    cursor: ${prop => (prop.theme.isDesktop ? 'pointer' : 'none')};
    &:focus {
      outline: 1px solid rgba(0, 0, 0, .1);
    }
  }

  a {
    color: inherit;
    text-decoration: none;
    :focus {
      outline: none;
    }
  }

  img {
    display: block;
  }

  strong {
    font-weight: bold;
  }

  div, a, button, li {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }
  // fix style: coinbase modal
  // .-cbwsdk-css-reset {
  //   .-cbwsdk-extension-dialog-box-top-install-region {
  //     button {
  //       display: flex !important;
  //       justify-content: center !important;
  //       align-items: center !important;
  //     }
  //   }
  //   .-cbwsdk-snackbar-instance {
  //     width: 100%;
  //   }
  // }
`

export { darkTheme, lightTheme, defaultTheme, GlobalStyle, flexCenter, gradient }
