import styled from 'styled-components'
import Button from '../../components/Button'
import RTable from '../../components/Table'
import { defaultTheme, flexCenter } from '../../style'
import RefreshIcon from '../../images/refresh.svg'
import { useEffect, useState } from 'react'
import { useAppSelector } from '../../helpers/hooks'
import MobileTable from '../../components/MobileTable'
import CONFIG from '../../config'
import { postMethod } from '../../http'

const Wrapper = styled.div`
  display: flex;
  height: ${props => props.theme.isDesktop ? 'calc(100vh - 101px)' : 'calc(100vh - 91px)'};
  width: 100%;
  align-items: center;
  margin: 0 auto;
  overflow-y: auto;

  .options {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: ${props => props.theme.isDesktop ? '26px 64px' : '20px'} ;
    width: ${props => props.theme.isDesktop ? '1165px' : '100%'};
    .refresh-icon {
      cursor: ${props => props.theme.isDesktop ?  'pointer': 'none'}
      width: 24px;
      height: 24px;
    }
  }
`
export const CheckBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  .item {
    ${flexCenter}
    min-width: 85px;
    height: 40px;
    border-radius: 32px;
    border: 1px solid #757575;
    background: #000;
    color: #757575;
    font-family: Inter;
    font-size: 14px;
    ont-weight: 700;
    cursor: ${props => props.theme.isDesktop ?  'pointer': 'none'};
    padding: 0 20px;
    margin-right: 20px;
    ${props => !props.theme.isDesktop && `
      font-size: 12px;
      margin-right: 12px;
    `}
  }
  .current {
    border-color: ${defaultTheme.primaryColor};
    color: ${defaultTheme.bgColor};
    background: rgba(0, 123, 110, 0.3);
  }
`

export interface TokenItem {
  currentSupply: string
  deployer: string
  hash: string
  holders: string
  lim: string
  max: string
  tick: string
  repeatMint: string
  isSeal: boolean
}


const ALL = 'All'
const INPROGRESS = 'In-Progress'
const COMPLETE = 'Complete'
const Main: React.FC = () => {
  const [status, setState] = useState(ALL)
  const isDesktop = useAppSelector(state => state.app.isDesktop)
  const [list, setList] = useState<TokenItem[]>([])
  const [hasMore, sethasMore] = useState(true)
  const [page, setPage] = useState(1)

  useEffect(() => {
    getList(page)
  }, [])

  const getList = (index: number) => {
    postMethod(`${CONFIG.URL}/getAllDeployedTicks`, {page: index, size: 10}).then(res => {
      const {ticks, pageDetial} = res.data
      const {totalSize, currentPage} = pageDetial
      if (index === 1) {
        setList([...ticks])
      } else {
        setList([...list, ...ticks])
      }
      sethasMore(totalSize > currentPage * 10)
      setPage(currentPage)
    })
  }

  const nextFunc = () => {
    getList(page + 1)
  }

  return (
    <Wrapper>
      <div className="options">
        <CheckBoxWrapper>
          {[ALL].map(item => (
            <div
              key={item}
              className={status === item ? 'item current' : 'item'}
              onClick={() => setState(item)}
            >
              {item}
            </div>
          ))}
        </CheckBoxWrapper>

        <img className="refresh-icon" onClick={() => getList(1)} src={RefreshIcon} />
      </div>
      {
        isDesktop ?  <RTable data={{
          list,
          hasMore,
          nextFunc
        }} /> : <MobileTable data={{
          list,
          hasMore,
          nextFunc
        }}/>
      }

    </Wrapper>
  )
}

export default Main
