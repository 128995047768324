import React from 'react'
import { RouteObject } from 'react-router-dom'
import Home from './pages/Home'
import Main from './pages/Main'
import Market from './pages/Market'
import Mint from './pages/Mint'
import Personal from './pages/Personal'
import Token from './pages/Token'


export const routes: RouteObject[] = [
  { path: '/', element: <Home /> },
  { path: '/tokens', element: <Main /> },
  { path: '/mint/:tick', element: <Mint /> },
  { path: '/mint', element: <Mint /> },
  { path: '/personal', element: <Personal /> },
  { path: '/market', element: <Market /> },
  { path: '/market/token', element: <Market /> },
  { path: '/market/token/:tick', element: <Token /> },
]
export default routes
