import OpIcon from './images/optimism-logo.svg'
// import BscIcon from './images/bsc-logo.png'
import ETHIcon from './images/ethereum-logo.png'
import { Chain } from 'wagmi'

export enum ChainId {
  // MAINNET = 1,
  // BSC_TEST = 97,
  // OPTIMISM = 10,
  BEVM = 1501,
}

export const CHAIN_IDS_TO_NAMES = {
  // [ChainId.MAINNET]: 'mainnet',
  // [ChainId.BSC_TEST]: 'BSC_Testnet',
  [ChainId.BEVM]: 'BEVM',
}
/**
 * Array of all the supported chain IDs
 */
export const ALL_SUPPORTED_CHAIN_IDS: ChainId[] = Object.values(ChainId).filter(
  id => typeof id === 'number',
) as ChainId[]

// 默认显示的链
export const DEFAULT_NETWORK = ChainId.BEVM
export const NetworkContextName = 'NETWORK'

export const SUPPORTED_CHAIN_IDS = [1501]
interface NetworkConfig {
  [key: number]: {
    chainId: typeof SUPPORTED_CHAIN_IDS
    chainIdHex: string
    chainName: string
    rpcUrls: string[]
    logo: string
    explorer: string
    nativeCurrency: {
      name: string
      symbol: string
      decimals: number
    }
  }
}
export const NETWORK_CONFIG: NetworkConfig = {
  // [ChainId.MAINNET]: {
  //   chainId: [ChainId.MAINNET],
  //   chainName: 'Ethereum',
  //   rpcUrls: ['https://eth-mainnet.nodereal.io/v1/1659dfb40aa24bbb8153a677b98064d7'],
  //   logo: ETHIcon,
  //   explorer: 'https://etherscan.io/',
  //   nativeCurrency: {
  //     name: 'ETH',
  //     symbol: 'ETH',
  //     decimals: 18,
  //   },
  // },
  // [ChainId.BSC_TEST]: {
  //   chainId: [ChainId.BSC_TEST],
  //   chainName: 'BSC_Testnet',
  //   rpcUrls: [
  //     'https://data-seed-prebsc-2-s3.binance.org:8545',
  //     'https://data-seed-prebsc-1-s3.binance.org:8545',
  //     'https://data-seed-prebsc-1-s2.binance.org:8545',
  //     'https://data-seed-prebsc-2-s1.binance.org:8545',
  //   ],
  //   logo: BscIcon,
  //   explorer: 'https://testnet.bscscan.com/',
  //   nativeCurrency: {
  //     name: 'tBNB',
  //     symbol: 'tBNB',
  //     decimals: 18,
  //   },
  // },
  [ChainId.BEVM]: {
    chainId: [ChainId.BEVM],
    chainIdHex: '0x5dd',
    chainName: 'BEVM',
    rpcUrls: ['https://rpc-canary-1.bevm.io/', 'https://rpc-1.bevm.io/'],
    logo: OpIcon,
    explorer: 'https://scan.bevm.io/',
    nativeCurrency: {
      name: 'BTC',
      symbol: 'BTC',
      decimals: 18,
    },
  },
}

export const isChainAllowed = (chainId?: number) => {
  if (!chainId) return false
  return SUPPORTED_CHAIN_IDS.includes(chainId)
}

export const BEVM_CHAIN: Chain = {
  id: 1501,
  name: 'BEVM',
  network: 'BEVM',
  nativeCurrency: {
    name: 'BEVM',
    symbol: 'BTC',
    decimals: 18,
  },
  rpcUrls: {
    default: {
      http: ['https://rpc-canary-1.bevm.io/', 'https://rpc-1.bevm.io/', 'https://rpc-2.bevm.io/'],
    },
    public: {
      http: ['https://rpc-canary-1.bevm.io/', 'https://rpc-1.bevm.io/', 'https://rpc-2.bevm.io/'],
    },
  },
  blockExplorers: {
    default: {
      name: 'BEVM Explorer',
      url: 'https://scan.bevm.io/',
    }
  }
}
