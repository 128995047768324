import { createConfig, configureChains } from 'wagmi'
import { publicProvider } from 'wagmi/providers/public'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { CoinbaseWalletConnector } from "wagmi/connectors/coinbaseWallet"
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'
import { BEVM_CHAIN } from './chain'

// Configure chains & providers with the Alchemy provider.
// Two popular providers are Alchemy (alchemy.com) and Infura (infura.io)
const { chains, publicClient, webSocketPublicClient } = configureChains(
  [BEVM_CHAIN],
  [publicProvider()],
)

// Set up wagmi config
export const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({
      chains,
     }),
    new CoinbaseWalletConnector({
      chains,
      options: {
        appName: 'BL2Rune',
      },
    }),
    // new WalletConnectConnector({
    //   chains,
    //   options: {
    //     projectId: '',
    //   },
    // }),
    // new InjectedConnector({
    //   chains,
    //   options: {
    //     name: 'Injected',
    //     shimDisconnect: true,
    //   },
    // }),
  ],
  publicClient,
  webSocketPublicClient,
})
