import { AccountDrawer, AccountModal, WalletModal, WalletWrapper } from './WalletStyle'
import WalletIcon from './images/wallet-icon.svg'
import WalletWarningIcon from './images/wallet-warning.svg'
import { useCallback, useEffect, useState } from 'react'
import Modal from '../Modal'
import {
  DEFAULT_NETWORK,
  isChainAllowed,
  NETWORK_CONFIG,
  SUPPORTED_CHAIN_IDS,
} from '../../web3/chain'
import toast from '../Toast/Toast'
import { shortenStr } from '../../helpers/utils'
import { useClipboard } from 'use-clipboard-copy'
import CopyIcon from './images/copy.svg'
import Button from '../Button'
import { useLoading } from '../Loading/Loading'
import Drawer from '../Drawer'
import StatusIcon from './StatusIcon'
import { useAppDispatch, useAppSelector } from '../../helpers/hooks'
import { useConnect, useAccount, useDisconnect, useNetwork, useSwitchNetwork } from 'wagmi'
import METAMASK from "../../images/metamask.png"
import WALLETCONNECT from "../../images/wallet-connect-icon.svg"
import COINBASE from "../../images/coinbase-wallet-icon.svg"
import OKX from "../../images/okx.png"
import { Link, useNavigate } from 'react-router-dom'
import { updateCount, updateokxAddress, updateokxChainId } from '../../redux/reducer'
import CONFIG from '../../config'

const WalletIconMap: {[key: string]: string} = {
    metaMask : METAMASK,
    walletConnect : WALLETCONNECT,
   coinbaseWallet : COINBASE,
}
const Wallet: React.FC = () => {
  const [showDisconnectModal, setShowDisconnectModal] = useState(false)
  const clipboard = useClipboard()
  const [switchChainModal, setSwitchChainModal] = useState(false)
  const [showAccountModal, setShowAccountModal] = useState(false)
  const loading = useLoading()
  const isDesktop = useAppSelector(state => state.app.isDesktop)
  const { connect, connectors, data: accountInfo, isLoading } = useConnect()
  const { address } = useAccount()
  const { disconnect } = useDisconnect()
  const { chain } = useNetwork()
  const { isSuccess, switchNetwork } = useSwitchNetwork()
  const navigate = useNavigate()
  const isInstallOKX = typeof window.okxwallet !== 'undefined'
  const dispatch = useAppDispatch()
  const okxAddress = useAppSelector(state => state.app.okxAddress)
  const okxChainId = useAppSelector(state => state.app.okxChainId)
  const count = useAppSelector(state => state.app.count)

  // useEffect(() => {
  //   window.okxwallet.on('accountsChanged', (accounts: string[]) => {
  //     dispatch(updateokxAddress({okxAddress: accounts[0]}))
  //   })
  //   window.okxwallet.on('chainChanged', (_chainId: string) => {
  //     dispatch(updateokxChainId({okxChainId: _chainId}))
  //   })
  // }, [dispatch])

  useEffect(() => {
    if (!count) return
    setShowDisconnectModal(true)
  }, [count])

  const onSelectChain = async (id: number) => {
    if (id === chain?.id) return
    if (okxAddress) {
      try {
        await window.okxwallet.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: NETWORK_CONFIG[DEFAULT_NETWORK].chainIdHex }],
        })

      } catch (switchError: any) {
        // This error code indicates that the chain has not been added to OKX Wallet.
        if (switchError.code === 4902) {
          try {
            await window.okxwallet.request({
              method: "wallet_addEthereumChain",
              params: [{ chainId: NETWORK_CONFIG[DEFAULT_NETWORK].chainIdHex, rpcUrl: NETWORK_CONFIG[DEFAULT_NETWORK].rpcUrls[0] }],
            })
          } catch (addError) {
            // handle "add" error
          }
        }
        // handle other "switch" errors
      }
      return
    }
    switchNetwork?.(id)
  }

  const handleConnect = useCallback(
    async (connector: any) => {
      if (isLoading || accountInfo?.connector?.id === connector.id) return
      try {
        await connect({ connector })
        dispatch(updateCount({count: 0}))
        setShowDisconnectModal(false)
      } catch (error) {
        console.debug(`connection error: ${error}`)
      }
    },
    [accountInfo, isLoading, connect, dispatch],
  )

  const handleDisconnect = async() => {
    if (okxAddress) {
      dispatch(updateokxAddress({okxAddress: ''}))
      setShowAccountModal(false)
      return
    }
    disconnect()
    setShowAccountModal(false)
  }

  const handleCopy = (value: string) => {
    if (clipboard.isSupported()) {
      clipboard.copy(value)
      setShowAccountModal(false)
      toast({ text: 'copy success' })
    }
  }

  const handleShowDisconnectModal = () => {
    if (chain?.id && !SUPPORTED_CHAIN_IDS.includes(chain?.id)) {
      setSwitchChainModal(true)
      return
    }
    setShowDisconnectModal(true)
  }

  const handleConnectNetwork = async () => {
    loading.show()
    try {
      await switchNetwork?.(DEFAULT_NETWORK)
      setSwitchChainModal(false)
      loading.hide()
    } catch (err) {
      toast({ text: 'Something Wrong.Please try again', type: 'error' })
      loading.hide()
    }
  }

  const getOptions = () => {
    const isMetaMask = !!window.ethereum?.isMetaMask
    const isCoinbaseWallet = !!window.ethereum?.isCoinbaseWallet
    return connectors.map(key => {
      const option = key
      if (!isDesktop) {
        if (
          (!window.web3 && !window.ethereum) ||
          (isMetaMask && option.name === 'MetaMask') ||
          (isCoinbaseWallet && option.name === 'Coinbase Wallet')
        ) {
          return (
            <div className="walletSeletor" key={key.id}>
              <div
                className={accountInfo?.connector?.id === option.id ? 'label current' : 'label'}
                onClick={() => {
                  handleConnect(option)
                }}
              >
                {option.name}
                {/* <img className="icon" src={option.iconName} /> */}
              </div>
            </div>
          )
        }
        return null
      }

      return (
        <div className="walletSeletor" key={option.id}>
          <div
            className={accountInfo?.connector?.id === option.id ? 'label current' : 'label'}
            onClick={() => handleConnect(option)}
          >
              <img className="icon" src={WalletIconMap[option.id]} />
            {option.name}
          </div>
        </div>
      )
    })
  }
  const DisconnectModal = () => {
    return (
      <Modal
        open={isDesktop && showDisconnectModal}
        title="Connect Wallet"
        onClose={() => setShowDisconnectModal(false)}
      >
        <WalletModal>
          {/* {
            isInstallOKX ?  <div className="walletSeletor">
            <div
              className={okxAddress ? 'label current' : 'label'}
              onClick={() => {
                window.okxwallet.request({ method: 'eth_requestAccounts' }).then((data: any) => {
                  dispatch(updateokxAddress({okxAddress: data[0]}))
                  setShowDisconnectModal(false)
                })
              }}
            >
              <img className="icon" src={OKX} />
              OKX Wallet
            </div>
          </div>
          : <div className="walletSeletor">
          <a href="https://chromewebstore.google.com/detail/mcohilncbfahbmgdjkbpemcciiolgcge" target="_blank" rel="noreferrer" className="label"
          >
            <img className="icon" src={OKX} />
            Install OKX Wallet
          </a>
        </div>
          } */}
           {getOptions()}
          </WalletModal>
      </Modal>
    )
  }

  const walletWrapper = () => {
    if (!address) {
      return (
        <>
          {isDesktop ? (
            <WalletWrapper onClick={handleShowDisconnectModal}>
              Connect Wallet
            </WalletWrapper>
          ):  <img src={WalletIcon} style={{"width": "30px", "marginRight": "10px"}} onClick={handleShowDisconnectModal}/>}
        </>
      )
    }
    if (!isChainAllowed(chain?.id) && okxChainId !== NETWORK_CONFIG[DEFAULT_NETWORK].chainIdHex) {
      return (
        <>
          { isDesktop ?  <WalletWrapper type="error" onClick={() => onSelectChain(DEFAULT_NETWORK)}>
             Wrong Network
           </WalletWrapper>
           : <img src={WalletWarningIcon} style={{"width": "30px", "marginRight": "10px"}} onClick={() => onSelectChain(DEFAULT_NETWORK)}/>
          }

        </>

      )
    }
    return (
      <>
        {isDesktop ?  <WalletWrapper onClick={() => setShowAccountModal(true)}>{address && shortenStr(address!)}</WalletWrapper>
        :  <img src={WalletIcon} style={{"width": "30px", "marginRight": "10px"}} onClick={() => setShowAccountModal(true)}/>}
      </>
    )
  }


  return (
    <>
      {walletWrapper()}
      <Modal
        title="Account"
        open={showAccountModal && isDesktop}
        onClose={() => setShowAccountModal(false)}
      >
        <AccountModal>
          <div className="content">
            <div className="row">
            {okxAddress && shortenStr(okxAddress)}
            {!okxAddress && address && shortenStr(address!)}
              <img src={CopyIcon} className="copy-icon" onClick={() => handleCopy(okxAddress || address!)} />
            </div>
            {/* <div className="desc">Connected with {accountInfo?.connector?.name}</div> */}
            {/* <Button text="Change" variant="outlined" onClick={() => setShowDisconnectModal(true)} /> */}
          </div>
          <div className="label" onClick={() => {
            navigate('/personal')
            setShowAccountModal(false)
          }}>
            My Runes
          </div>
          {isChainAllowed(chain?.id) && (
            <a
              className="label"
              href={`${NETWORK_CONFIG[chain?.id as number]?.explorer}address/${address}`}
              target="_blank"
              rel="noreferrer"
            >
              View On Explorer
            </a>
          )}

          <div className="label warning" onClick={handleDisconnect}>
            Disconnect
          </div>

        </AccountModal>
      </Modal>
      <DisconnectModal />
      <Modal
        title="Wrong Network"
        open={switchChainModal}
        onClose={() => setSwitchChainModal(false)}
      >
        <WalletModal>
          <img className="img" src={WalletWarningIcon} />
          <div className="content">Please switch to a currently supported network</div>
          <Button onClick={handleConnectNetwork} text="Switch Network" />
        </WalletModal>
      </Modal>
      <Drawer open={showAccountModal && !isDesktop} onClose={() => setShowAccountModal(false)}>
        <AccountDrawer>
          <div className="content">
            <div className="row">
              <div className="avatar">
                {accountInfo && <StatusIcon type={accountInfo.connector?.id} size="large" />}
              </div>
              {address && shortenStr(address)}
              <img src={CopyIcon} className="copy-icon" onClick={() => handleCopy(address!)} />
            </div>
            {/* {isDesktop && <div className="desc">Connected with {accountInfo?.connector?.name}</div>} */}
          </div>
          {/* <div className="label" onClick={() => setShowDisconnectModal(true)}>Change</div> */}
          {isChainAllowed(chain?.id) && (
            <a
              className="label"
              href={`${NETWORK_CONFIG[chain?.id as number]?.explorer}/address/${address}`}
              target="_blank"
              rel="noreferrer"
            >
              view on explorer
            </a>
          )}

          {/* <div className="label" onClick={handleDisconnect}>Disconnect</div> */}
        </AccountDrawer>
      </Drawer>
      <Drawer
        showCloseIcon={false}
        onClose={() => setShowDisconnectModal(false)}
        open={!isDesktop && showDisconnectModal}>
        <AccountDrawer>
            <div className='title'>Connect Wallet</div>
            {getOptions()}
        </AccountDrawer>

      </Drawer>
    </>
  )
}

export default Wallet
