'use strict'
const CONFIG = {
  TO: '0x30FF84570D3fD3F265CEa0E2598A9FC98757f2c5',
  URL: "https://bl2rune.com/api",
  verifyRune: 'book',
  QUERYBTC: 'https://api.binance.com/api/v3/ticker/price?symbol=BTCUSDT',
  MARKET_ADDRESS: '0xc0abba5724c2dad1f9753b39e0763a8ff5588a98'
}

export default CONFIG
