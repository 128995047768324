import { useEffect, useState } from "react"
import styled from "styled-components"
import GTable from "../../components/GTable"
import MGTable from "../../components/MGTable"
import toast from "../../components/Toast/Toast"
import CONFIG from "../../config"
import { useAppSelector } from "../../helpers/hooks"
import { postMethod } from "../../http"
import { defaultTheme, flexCenter } from "../../style"

export interface MarketItem {
  tick: string
  totalVolume: string
  supply: string
  holders: string
  floorPrice: string
  oneDayVolume: string
}
const Wrapper = styled.div`
  display: flex;
  height: ${props => props.theme.isDesktop ? 'calc(100vh - 101px)' : 'calc(100vh - 91px)'};
  width: 100%;
  align-items: center;
  margin: 0 auto;
  overflow-y: auto;
  .title {
    ${flexCenter};
    flex-direction: row;
    padding-top: 60px;
    padding-bottom: 30px;
    font-family: Inter;
    font-size: ${props => props.theme.isDesktop ? '50px' : '18px'};
    color: #DADADA;
    font-weight: 900;
    text-transform: uppercase;
    .main {
      color: ${defaultTheme.bgColor};
      margin: 0 10px;
    }
  }
`

const Market: React.FC = () => {
  const isDesktop = useAppSelector(state => state.app.isDesktop)
  const [list, setList] = useState<MarketItem[]>([])
  const [hasMore, sethasMore] = useState(true)
  const [page, setPage] = useState(1)

  useEffect(() => {
    getList(page)
  }, [])

  const getList = (index: number) => {
    if (index !==1 && !hasMore) return
    postMethod(`${CONFIG.URL}/getMarkets`, {page: index, size: 20}).then(res => {
      const {data: resData} = res
      if (!resData) {
        toast({text: res.message, type: 'error'})
        return
      }
      const {markets, pageDetial} = resData
      const {totalSize, currentPage} = pageDetial
      if (index === 1) {
        setList([...markets])
      } else {
        setList([...list, ...markets])
      }
      sethasMore(totalSize > currentPage * 10)
      setPage(currentPage)
    })
  }
  const nextFunc = () => {
    getList(page + 1)
  }
  return <Wrapper>
       <div className="title">
          <span>Trending in</span>
          <span className="main">BLR-20</span>
      </div>
      {
        isDesktop ?  <GTable data={{
          list,
          hasMore,
          nextFunc
        }}  /> : <MGTable data={{
          list,
          hasMore,
          nextFunc
        }}/>
      }
  </Wrapper>
}

export default Market
