import styled from 'styled-components'
import WalletConnectIcon from '../../images/wallet-connect-icon.svg'
import CoinbaseWalletIcon from '../../images/coinbase-wallet-icon.svg'
import MeatamaskIcon from '../../images/metamask.png'

type Size = 'normal' | 'large'
const IconWrapper = styled.div<{ size: Size }>``

interface StatusIconProps {
  size?: Size
  type?: string
}

const StatusIcon: React.FC<StatusIconProps> = ({ size = 'normal', type }) => {
  if (type === 'walletConnect') {
    return (
      <IconWrapper size={size}>
        <img src={WalletConnectIcon} alt={'WalletConnectIcon'} />
      </IconWrapper>
    )
  }
  if (type === 'coinbaseWallet') {
    return (
      <IconWrapper size={size}>
        <img src={CoinbaseWalletIcon} alt={'CoinbaseWalletIcon'} />
      </IconWrapper>
    )
  }
  return (
    <IconWrapper size={size}>
      <img src={MeatamaskIcon} alt={'MeatamaskIcon'} />
    </IconWrapper>
  )
}

export default StatusIcon
