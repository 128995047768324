import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core'
import Button from '../Button'
import { useNavigate } from 'react-router-dom'
import {formatEther, formatSARS, numToLocaleString, parseSARS, toFloor} from '../../helpers/utils'
import VerifyIcon from '../../images/verified-badge.svg'
import CONFIG from '../../config'
import { MarketItem } from '../../pages/Market/Market'
import BitcoinIcon from '../../images/bitcoin-icon.svg'
import SatsIcon from '../../images/sats-icon.svg'
import { InfiniteScrollWrapper, TableWrapper, useStyles } from '../Table/Table'
import { BigNumber } from 'ethers'




export interface MaterialTableProps {
  data: {
    list: MarketItem[],
    hasMore: boolean,
    nextFunc: () => void,
  }
}

const RTable: React.FC<MaterialTableProps> = ({ data }) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const {list, hasMore, nextFunc} = data

  return (
    <TableWrapper>
      <TableContainer component={Paper}>
      <InfiniteScrollWrapper
            dataLength={list.length}
            next={nextFunc}
            hasMore={hasMore}
            height={670}
            loader={null}
            endMessage={null}
          >
        <Table className={classes.table} stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeaderCell}>Number</TableCell>
              <TableCell className={classes.tableHeaderCell}>Name</TableCell>
              <TableCell className={classes.tableHeaderCell}>Volume(24h)</TableCell>
              <TableCell className={classes.tableHeaderCell}>Total Volume</TableCell>
              <TableCell className={classes.tableHeaderCell}>Floor Price</TableCell>
              <TableCell className={classes.tableHeaderCell}><></></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.length === 0 && <TableRow>
                <TableCell colSpan={6}><div className='no-data'>No Data</div></TableCell>
              </TableRow>}
            {list.length > 0 && list.map((row, index) => {
              return <TableRow key={row.tick} className={classes.tableRow}>
                <TableCell className={classes.tableCell}>{index + 1}</TableCell>
                <TableCell className={classes.tableCell}>
                  <div className="row">
                    <>{row.tick}</>
                    {CONFIG.verifyRune === row.tick &&  <img src={VerifyIcon} className="icon" />}
                  </div>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {
                      formatEther(BigNumber.from(row.oneDayVolume)) < 0.0001 ?
                    <div className="row">
                      <img src={SatsIcon} className="coin-icon"/>
                      <span>{toFloor(formatSARS(BigNumber.from(row.oneDayVolume)), 3)}</span>
                      <span  className='unit'>Sats</span>
                    </div>
                    :
                    <div className="row">
                    <img src={BitcoinIcon} className="coin-icon"/>
                    <span>{toFloor(formatEther(BigNumber.from(row.oneDayVolume)))}</span>
                    <span  className='unit'>BTC</span>
                  </div>
                  }

                </TableCell>
                <TableCell className={classes.tableCell}>
                <div className="row">
                {
                  formatEther(BigNumber.from(row.oneDayVolume)) < 0.0001 ?
                    <div className="row">
                      <img src={SatsIcon} className="coin-icon"/>
                      <span>{toFloor(formatSARS(BigNumber.from(row.totalVolume)), 3)}</span>
                      <span  className='unit'>Sats</span>
                    </div>
                    :
                    <div className="row">
                    <img src={BitcoinIcon} className="coin-icon"/>
                    <span>{toFloor(formatEther(BigNumber.from(row.totalVolume)))}</span>
                    <span  className='unit'>BTC</span>
                  </div>
                  }
                  </div>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <div className="row">
                    <img src={SatsIcon} className="coin-icon"/>
                    <span>{toFloor(parseSARS(row.floorPrice), 3)}</span>
                    <span  className='unit'>Sats</span>
                  </div>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <Button
                    text='Trade'
                    onClick={() => navigate(`/market/token/${row.tick}`)}/>
                </TableCell>
              </TableRow>
            })}
          </TableBody>
        </Table>
        </InfiniteScrollWrapper>
      </TableContainer>
    </TableWrapper>
  )
}

export default RTable
