import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { numToLocaleString } from '../../helpers/utils'
import { TokenItem } from '../../pages/Main/Main'
import { flexCenter } from '../../style'
import LineProgress from '../LineProgress'
import LoadingButton from '../LoadingButton'
import VerifyIcon from '../../images/verified-badge.svg'
import CONFIG from '../../config'
import { InfiniteScrollWrapper, MaterialTableProps } from '../Table/Table'

const Wrapper = styled.div`
  display: flex;
  padding: 20px;
  width: 100%;
  .box {
    width: 100%;
    border-radius: 25px;
    border: 1px solid #C5C5C5;
    background: linear-gradient(112deg, #393939 -4.38%, rgba(0, 0, 0, 0.47) 91.02%);
    padding: 20px 30px;
    margin-bottom: 20px;
    .row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      color: #C1C1C1;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: capitalize;
      margin-bottom: 20px;
    }
    .header {
      margin-bottom: 20px;
      font-size: 22px;
      color: #FFF;
      .icon {
        width: 20px;
        margin-left: 10px;
        margin-right: auto;
      }
    }
    .btns {
      ${flexCenter}
    }
  }

`

const MobileTable: React.FC<MaterialTableProps> = ({data}) => {
  const {list, hasMore, nextFunc} = data
  const navigate = useNavigate()

  return <Wrapper>
       <InfiniteScrollWrapper
            dataLength={list.length}
            next={nextFunc}
            hasMore={hasMore}
            height={670}
            loader={null}
            endMessage={null}
          >
    {
     !!list.length &&  list.map((item, index) => {
          const mintPre = Number(item.currentSupply) / Number(item.max) * 100
          const pre = mintPre.toFixed(2) <= '0.00' ? '0.01' : mintPre.toFixed(2)
        return (
        <div className='box' key={item.hash}>
          <div className='row header'>
            <div>{item.tick}</div>
            {CONFIG.verifyRune === item.tick &&  <img src={VerifyIcon} className="icon" />}
            <div>#{index + 1}</div>
          </div>
          <div className='row'><LineProgress size="large" progress={mintPre}/></div>
          <div className='row'>
            <span>Mint%</span>
            <span>{mintPre === 0 ? 0 :pre}%</span>
          </div>
          <div className='row'>
            <span>Supply</span>
            <span>{numToLocaleString(item.max)}</span>
          </div>
          <div className='row'>
            <span>Holders</span>
            <span>{numToLocaleString(item.holders)}</span>
          </div>
          {/* <div className='row'>
            <span>Transcation</span>
            <span>{Number(item.currentSupply)  / Number(item.lim)}</span>
          </div> */}
          <div className='btns'>
            <LoadingButton text='Mint' disabled={Number(item.max) === Number(item.currentSupply) || item.isSeal} size='large' onClick={() => navigate(`/mint/${item.tick}`)}/>
          </div>

        </div>
      )})
    }
    </InfiniteScrollWrapper>


  </Wrapper>
}

export default MobileTable
